import * as React from "react";

export interface RadioProps {
	/**	Specifies ID of the radio. Can not be changed. Initialized during initial rendering */
	id?: string;
	/** Specifies whether or not the radio is selected */
	checked: boolean;
	/** Disable radio */
	disabled: boolean;
	/** Specifies the label for the radio if value is not empty */
	label?: string;
	/** Defines a radio group by giving each of radio in the group the same name */
	name?: string;
	/** Defines the unique value associated with each radio button */
	value?: string;
	/** The callback function that is triggered when the state changes */
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface RadioState {
	checked: boolean;
}

export class RadioComponent extends React.Component<RadioProps, RadioState> {
	static defaultProps: Pick<
		RadioProps,
		"id" | "checked" | "disabled" | "label" | "name" | "value"
	> = {
		id: "",
		checked: false,
		disabled: false,
		label: "",
		name: "",
		value: "",
	};
	constructor(props: RadioProps) {
		super(props);
		const {checked} = props;
		this.state = {
			checked,
		};
	}

	componentDidUpdate(prevProps: RadioProps): void {
		const {checked} = this.props;
		if (prevProps.checked !== checked) {
			this.setState({checked});
		}
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {
			target: {checked},
		} = event;
		this.setState({checked});
		const {onChange} = this.props;
		if (onChange) {
			onChange(event);
		}
	};

	render(): JSX.Element {
		const {id, disabled, label, name, value} = this.props;
		const {checked} = this.state;
		return (
			<label htmlFor={id}>
				<input
					id={id}
					type="radio"
					checked={checked}
					onChange={disabled ? null : this.handleChange}
					disabled={disabled}
					name={name}
					value={value}
				/>
				{label && <span>{label}</span>}
			</label>
		);
	}
}

export default RadioComponent;
