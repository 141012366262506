import {darken, lighten} from "polished";
import {ActionPalette, DefaultPalette} from "src/components/themes/theme";

const generateCommonColorSpectrum = (color: string): DefaultPalette => ({
	darker: darken(0.3, color),
	dark: darken(0.1, color),
	medium: color,
	light: lighten(0.1, color),
	lighter: lighten(0.3, color),
});

const generateLinkSpectrum = (color: string): ActionPalette => ({
	visited: darken(0.2, color),
	default: color,
	hover: lighten(0.1, color),
});

export {generateCommonColorSpectrum, generateLinkSpectrum};
