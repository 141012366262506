import React, {
	FunctionComponent,
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
} from "react";
import {
	ToastProvider,
	useToasts,
	Placement,
	AppearanceTypes,
} from "react-toast-notifications";

export interface MessageProps {
	/** The placement of message toast on body viewport component */
	placement?: Placement;
	/** Is message toast automatically dismiss in 5 seconds? */
	autoDismiss?: boolean;
	/** The title of notification */
	title?: string;
	/** Appearance style type (inherited from global theme) */
	appearance?: AppearanceTypes;
}

const Toast = ({title, appearance}) => {
	const {addToast} = useToasts();

	useEffect(() => {
		addToast(title, {appearance});
	}, [title, appearance]);

	return <></>;
};
const Message: FunctionComponent<MessageProps> = ({
	placement = "bottom-right",
	autoDismiss = true,
	title = "Saved successfully",
	appearance = "info",
}) => {
	return (
		<ToastProvider
			placement={placement}
			children={<Toast title={title} appearance={appearance} />}
			autoDismiss={autoDismiss}
		/>
	);
};

interface MessageEmitterHandle {
	emitMessage: (messageProps?: MessageProps) => void;
}

interface MessageEmitterProps {
	props?: MessageProps;
}

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.
const MessageEmitterComponent: React.ForwardRefRenderFunction<
	MessageEmitterHandle,
	MessageEmitterProps
> = (props, ref) => {
	const [message, setMessage] = useState(null);
	// The component instance will be extended
	// with whatever you return from the callback passed
	// as the second argument
	useImperativeHandle(ref, () => ({
		emitMessage(messageProps?: MessageProps) {
			setMessage(messageProps ? messageProps : props);
		},
	}));

	return message ? <Message {...message} /> : <></>;
};

const MessageEmitter = forwardRef(MessageEmitterComponent);

export {Message as default, MessageEmitter};
