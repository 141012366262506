import React, {PureComponent, KeyboardEvent} from "react";
import debounce from "lodash.debounce";
import isNumber from "lodash.isnumber";
import {Constants} from "../util/constants";

export enum InputType {
	TEXT = "text",
}

export interface NumericComponentProps {
	label?: string;
	units?: string;
	classNames?: string;
	wrapperClassNames?: string;
	disabled?: boolean;
	value: number | string;
	defaultValue?: number;
	title?: string;
	step?: number;
	min?: number;
	max?: number;
	debounceDelay?: number;
	onChange(value: number): void;
}

export interface NumericComponentState {
	internalValue: string;
	isDebounced: boolean;
}

export class NumericInputComponent extends PureComponent<
	NumericComponentProps,
	NumericComponentState
> {
	static defaultProps: Pick<
		NumericComponentProps,
		| "disabled"
		| "defaultValue"
		| "debounceDelay"
		| "step"
		| "classNames"
		| "wrapperClassNames"
	> = {
		disabled: false,
		defaultValue: 0,
		debounceDelay: 0,
		step: 1,
		classNames: "",
		wrapperClassNames: "",
	};

	debouncedUpdate: ReturnType<typeof debounce>;

	constructor(props: NumericComponentProps) {
		super(props);
		const {value, defaultValue, debounceDelay} = this.props;

		this.state = {
			// we have to extract number value from props, because sometimes component receives it in format "32px"
			internalValue: this.getNumberValue(this.stringify(value || defaultValue)),
			isDebounced: false,
		};

		this.saveChanges = debounce(this.saveChanges, debounceDelay);
		// you can't use this.debouncedUpdate = debounce(this.debouncedUpdate, ...),
		// because it leads to TS error if you want to this.debouncedUpdate.cancel()
		this.debouncedUpdate = debounce(this.setCorrectValue, debounceDelay);
	}

	stringify = (value: number | string): string => String(value);

	isEmpty = (value: string): boolean => value.length === 0;

	endsWithDot = (value: string): boolean => value.endsWith(".");

	isMinus = (value: string): boolean => value === "-";

	getNumberValue = (value: string): string => {
		const numberMatch = value.match(Constants.NUMBER_REGEX);
		return numberMatch ? numberMatch[0] : "";
	};

	getCorrectValue = (value: number): number => {
		const {min, max} = this.props;

		if (isNumber(min) && value < min) {
			return min;
		}

		if (isNumber(max) && value > max) {
			return max;
		}

		return value;
	};

	isValidValue = (value: string): boolean => {
		const numberValue = Number(value);
		const {min, max} = this.props;
		const isValidMin = !isNumber(min) || numberValue >= min;
		const isValidMax = !isNumber(max) || numberValue <= max;

		return isValidMin && isValidMax;
	};

	saveChanges = (data: string): void => {
		this.props.onChange(Number(data));
		this.setState({isDebounced: false});
	};

	setCorrectValue = (data: string): void => {
		const {value, defaultValue} = this.props;

		if (this.isEmpty(data) || this.endsWithDot(data) || this.isMinus(data)) {
			const numberValue = this.getNumberValue(
				this.stringify(value || defaultValue)
			);
			this.setState({internalValue: numberValue});
		} else if (!this.isValidValue(data)) {
			const correctValue = this.stringify(this.getCorrectValue(Number(data)));
			this.setState({internalValue: correctValue});
			this.saveChanges(correctValue);
		}
	};

	handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		e.preventDefault();
		e.stopPropagation();
		const {value} = e.target;
		this.updateValueState(value);
	};

	updateValueState = (value: string): void => {
		const numberMatch = value.match(Constants.NUMBER_REGEX);
		const isMinus = this.isMinus(value);

		let numberValue = numberMatch ? numberMatch[0] : "";

		if (!numberValue && isMinus) {
			numberValue = value;
		}

		this.setState({internalValue: numberValue, isDebounced: true});

		if (
			!this.isEmpty(numberValue) &&
			!this.endsWithDot(numberValue) &&
			!isMinus
		) {
			if (this.isValidValue(numberValue)) {
				this.debouncedUpdate.cancel();
				this.setState({isDebounced: false});
				this.saveChanges(numberValue);
			} else {
				this.debouncedUpdate(numberValue);
			}
		}
	};

	onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const {keyCode} = e;
		const isKeyUp = keyCode === Constants.KEY_UP;
		const isKeyDown = keyCode === Constants.KEY_DOWN;

		if (isKeyUp || isKeyDown) {
			// have to call this method to avoid moving caret in the input
			e.preventDefault();

			const {step} = this.props;
			const {internalValue} = this.state;
			const internalNumberValue = Number(internalValue);

			if (isKeyDown) {
				this.updateValueState(this.stringify(internalNumberValue - step));
			}

			if (isKeyUp) {
				this.updateValueState(this.stringify(internalNumberValue + step));
			}
		}
	};

	componentWillUnmount() {
		this.debouncedUpdate.cancel();
	}

	componentDidUpdate(prevProps: NumericComponentProps) {
		const prevNumberValue = this.getNumberValue(
			this.stringify(prevProps.value)
		);
		const currentNumberValue = this.getNumberValue(
			this.stringify(this.props.value)
		);

		if (
			!this.state.isDebounced &&
			prevNumberValue !== currentNumberValue &&
			currentNumberValue !== this.state.internalValue
		) {
			this.setState({internalValue: currentNumberValue});
		}
	}

	onBlur = () => {
		const {internalValue} = this.state;

		if (
			this.isEmpty(internalValue) ||
			this.endsWithDot(internalValue) ||
			this.isMinus(internalValue)
		) {
			this.debouncedUpdate(internalValue);
		}
	};

	render() {
		const {
			label,
			units,
			classNames,
			disabled,
			wrapperClassNames,
			title,
		} = this.props;

		const {internalValue} = this.state;

		return (
			<label data-unit={units} className={wrapperClassNames} title={title}>
				{label}
				<input
					// we can't use input type="number" here,
					// because each browser provides us with different values on dot typing
					type={InputType.TEXT}
					className={classNames}
					disabled={disabled}
					value={internalValue}
					onChange={this.handleChange}
					onKeyDown={this.onKeyDown}
					onBlur={this.onBlur}
				/>
			</label>
		);
	}
}

export default NumericInputComponent;
