import React from "react";
import debounce from "lodash.debounce";

export interface DebounceChangeProps<T> {
	/** Callback that is fired when a user changes the slider's value.
	 * A debounced function that delays invoking func until
	 * after "onChangeWait" milliseconds have elapsed since the last time the debounced function was invoked.
	 */
	onChange?: (event: React.ChangeEvent<T>) => void;
	/** The number of milliseconds to delay. */
	onChangeWait: number;
	/** The maximum time func is allowed to be delayed before it's invoked. */
	onChangeMaxWait: number;
}

type DebouncedChangeHandler<T> = ((event: React.ChangeEvent<T>) => void) &
	_.Cancelable;

export const withDebounceChange = <T, P, S>(
	WrappedComponent: React.ComponentClass<P, S> | React.FunctionComponent<P>
) => {
	return class DebounceChangeComponent extends React.Component<
		DebounceChangeProps<T> & P,
		S
	> {
		static displayName = `debounced(${WrappedComponent.displayName})`;
		/*
			Due to an issue with required props, defaultProps, and typing a component that implement this HoC will have its
			optional defaultProps be marked as required. I haven't been able to find a clean and generic approach that will
			solve this issue for us. When using this component we must be aware that all props will be required regardless of
			defaultProps. It may make sense in the future to use functional components and hooks in place of an HoC.
		 */
		static defaultProps = {
			...(WrappedComponent.defaultProps || {}),
			onChangeWait: 200,
			onChangeMaxWait: 500,
		};

		debouncedChangeHandler: DebouncedChangeHandler<T>;

		constructor(props: DebounceChangeProps<T> & P) {
			super(props);
			const {onChange, onChangeWait, onChangeMaxWait} = this.props;

			const debounceOptions: _.DebounceSettings = {
				...(onChangeMaxWait && {
					maxWait: onChangeMaxWait,
				}),
			};
			if (onChange) {
				this.debouncedChangeHandler = debounce(
					onChange,
					onChangeWait,
					debounceOptions
				);
			}
		}

		componentWillUnmount() {
			if (this.debouncedChangeHandler) {
				this.debouncedChangeHandler.flush();
			}
		}

		handleChange = (event: React.ChangeEvent<T>) => {
			const {onChange} = this.props;
			if (onChange) {
				event.persist();
				this.debouncedChangeHandler(event);
			}
		};

		render() {
			return <WrappedComponent {...this.props} onChange={this.handleChange} />;
		}
	};
};

export default withDebounceChange;
