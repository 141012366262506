import React from "react";
import {SketchPicker, ColorResult} from "react-color";

const styles: {[key: string]: React.CSSProperties} = {
	swatch: {
		width: "24px",
		height: "16px",
		padding: "2px",
		background: "#fff",
		borderColor: "#d3d3d3",
		borderStyle: "solid",
		borderWidth: "1px",
		cursor: "pointer",
		marginBottom: "2px",
	},
	color: {
		width: "100%",
		height: "100%",
	},
	popover: {
		position: "absolute",
	},
	cover: {
		position: "fixed",
		top: "0px",
		right: "0px",
		bottom: "0px",
		left: "0px",
	},
};

export interface ColorPickerProps {
	/** What color is active on the color picker. Color accepts a string of a hex color */
	color: string;
	/** Array of colors for default colors at bottom of picker */
	presetColors?: string[];
	/** Remove alpha option from picker */
	disableAlpha?: boolean;
	/** Pass a function to call every time the color is changed */
	onChange?: (color: string) => void;
}

interface ColorPickerState {
	color: string;
	displayColorPicker: boolean;
}

export class ColorPickerComponent extends React.Component<
	ColorPickerProps,
	ColorPickerState
> {
	static defaultProps: ColorPickerProps = {
		color: "",
		presetColors: [],
		disableAlpha: false,
	};

	constructor(props: ColorPickerProps) {
		super(props);

		const {color} = props;
		this.state = {
			color,
			displayColorPicker: false,
		};
	}

	componentDidUpdate(prevProps: ColorPickerProps): void {
		const {color} = this.props;
		if (prevProps.color !== color) {
			this.setState({color});
		}
	}

	handleChangeComplete = (color: ColorResult): void => {
		const {hex} = color;
		const {onChange} = this.props;
		if (onChange) {
			onChange(hex);
		}
	};

	handleChange = (color: ColorResult): void => {
		const {hex} = color;
		this.setState({color: hex});
	};

	togglePicker = (): void => {
		const {displayColorPicker} = this.state;
		this.setState({displayColorPicker: !displayColorPicker});
	};

	closePicker = (): void => {
		const {color} = this.props;
		this.setState({displayColorPicker: false, color});
	};

	render(): JSX.Element {
		const {presetColors, disableAlpha} = this.props;
		const {color, displayColorPicker} = this.state;
		return (
			<div>
				<div style={styles.swatch} onClick={this.togglePicker}>
					<div style={{...styles.color, background: color}} />
				</div>
				{displayColorPicker && (
					<div style={styles.popover}>
						<div style={styles.cover} onClick={this.closePicker} />
						<SketchPicker
							color={color}
							presetColors={presetColors}
							disableAlpha={disableAlpha}
							onChange={this.handleChange}
							onChangeComplete={this.handleChangeComplete}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default ColorPickerComponent;
