import React from "react";
import Wrapper from "./NavigationWrapper";
import {Item} from "./NavigationItem";
import {Submenu} from "./NavigationSubmenu";
import {WrapperProps} from "./Navigation.types";
export class NavigationComponent extends React.Component<WrapperProps, {}> {
	static Item = Item;
	static SubMenu = Submenu;
	render() {
		return <Wrapper {...this.props} />;
	}
}
