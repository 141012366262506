import React, {useEffect} from "react";
import {Formik} from "formik";
import withAutoCommit from "../../form/withAutoCommit";
import {Form, FormOwnProps, FormValues} from "./Form";

const AutoCommitForm = withAutoCommit<FormOwnProps, FormValues>(Form);

export interface ProductLinkPropertiesFormProps extends FormOwnProps {
	/** Initial field values of the form.
	 * Even if your form is empty by default, you must initialize all fields with initial values.
	 */
	initialValues: FormValues;
	/** Updatable form state. Use when need to change form state from outside.
	 * If values are empty, only initial state wiil be applied
	 */
	values?: FormValues;
	/** Form submission handler */
	onSubmit?: (values: FormValues) => void;
}

export const ProductLinkPropertiesForm: React.FC<ProductLinkPropertiesFormProps> = (
	props: ProductLinkPropertiesFormProps
) => {
	const {initialValues, values, onSubmit} = props;

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit}>
			{formProps => {
				const {setValues} = formProps;

				useEffect(() => {
					if (values) {
						setValues(values, false);
					}
				}, [values]);

				return <AutoCommitForm {...props} {...formProps} />;
			}}
		</Formik>
	);
};

export default ProductLinkPropertiesForm;
