import React, {useCallback, ChangeEvent} from "react";
import {FormikProps, Form as FormikForm, Field, ErrorMessage} from "formik";

import * as StyledForm from "../../form/StyledForm";

import {SelectOption} from "../../form/types";
import {BaseLink} from "../linkPropertiesForm/LinkPropertiesForm.types";

export interface FormValues extends BaseLink {}

export interface FormOptions {
	pages: SelectOption[];
}

export interface FormOwnProps {
	/** Select options */
	options: FormOptions;
}

export type FormProps = FormOwnProps & FormikProps<FormValues>;

export const Form: React.FC<FormProps> = (props: FormProps): JSX.Element => {
	const {options, values, setFieldValue} = props;

	const handleDestinationPageChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>): void =>
			setFieldValue(
				"pageNumber",
				Number(
					event.currentTarget.options[event.currentTarget.selectedIndex].value
				)
			),
		[]
	);

	return (
		<FormikForm>
			{/** Destination page */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label>Destination page</StyledForm.Label>
				<StyledForm.Field>
					<Field
						as="select"
						options={options.pages}
						disabled={false}
						value={values.pageNumber}
						onChange={handleDestinationPageChange}
					>
						{options.pages.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Field>
				</StyledForm.Field>
				<ErrorMessage name="pageNumber" />
			</StyledForm.FieldWrapper>
		</FormikForm>
	);
};

export default Form;
