import * as React from "react";
import {Component, ChangeEvent} from "react";
import {CheckBoxPresenter} from "./CheckBoxPresenter";
import nanoid from "nanoid";

// this is needed so we dont have to the onChange event defined by the checkbox.stories.tsx or the end-user
// I dont think we want them to have to define their own click-handler
interface CheckBoxProps {
	checked: boolean;
	label: string;
	disabled: boolean;
	onChange?: (isChecked: boolean) => void;
}

interface CheckBoxState {
	checked: boolean;
}

class CheckBoxComponent extends Component<CheckBoxProps, CheckBoxState> {
	static defaultProps = {
		onChange: () => undefined,
	};

	id = "";

	constructor(props: CheckBoxProps) {
		super(props);
		this.id = nanoid();
		const {checked} = props;
		this.state = {
			checked,
		};
	}

	// Its a bit strange to have event handling in the component here, but i dont think it belongs in the presenter
	//  and I need it to update my state here
	handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
		let {checked} = event.target;
		this.setState({checked: checked});
		const {onChange} = this.props;
		if (onChange) {
			onChange(checked);
		}
	};

	render() {
		const {label, disabled} = this.props;
		const {checked} = this.state;
		return (
			<CheckBoxPresenter
				checked={checked}
				label={label}
				disabled={disabled}
				onChange={this.handleCheckboxChange}
				id={this.id}
			/>
		);
	}
}
export {
	CheckBoxComponent,
	CheckBoxComponent as default,
	CheckBoxComponent as CheckBox,
};
