import React from "react";
import {Formik, FormikProps, Form, Field, ErrorMessage} from "formik";

import withAutoCommit from "../../form/withAutoCommit";
import LinkTarget from "../linkTarget/LinkTarget";
import * as StyledForm from "../../form/StyledForm";

import {
	object as YupObject,
	number as YupNumber,
	string as YupString,
} from "yup";

import {SelectOption} from "../../form/types";
import {BaseLink} from "../linkPropertiesForm/LinkPropertiesForm.types";

export interface FormValues extends BaseLink {}

export interface FormOptions {
	target: SelectOption[];
}

interface OtherProps {
	options: FormOptions;
}

type InnerFormProps = OtherProps & FormikProps<FormValues>;

const InnerForm: React.FC<InnerFormProps> = (
	props: InnerFormProps
): JSX.Element => {
	const {options, values, setFieldValue} = props;

	function setTarget(value: string) {
		setFieldValue("target", value);
	}

	function setLightBox(value: boolean) {
		setFieldValue("lightBox", value);
	}

	function setLightBoxWidth(value: number) {
		setFieldValue("lightBoxWidth", value);
	}

	function setLightBoxHeight(value: number) {
		setFieldValue("lightBoxHeight", value);
	}

	return (
		<Form>
			{/** URL */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="url">URL</StyledForm.Label>
				<StyledForm.Field>
					<Field name="url" type="url" placeholder="http://" />
				</StyledForm.Field>
				<ErrorMessage name="url" />
			</StyledForm.FieldWrapper>
			{/** Open link in */}
			<LinkTarget
				target={options.target}
				values={values}
				setTarget={setTarget}
				setLightBox={setLightBox}
				setLightBoxWidth={setLightBoxWidth}
				setLightBoxHeight={setLightBoxHeight}
			/>
		</Form>
	);
};

const AutoCommitInnerForm = withAutoCommit<InnerFormProps, FormValues>(
	InnerForm
);

const ExternalLinkPropertiesFormSchema = YupObject<FormValues>().shape({
	url: YupString().url(),
	lightBoxWidth: YupNumber().min(0),
	lightBoxHeight: YupNumber().min(0),
});

export interface ExternalLinkPropertiesFormProps extends OtherProps {
	initialValues: FormValues;
	onSubmit?: (values: FormValues) => void;
}

export const ExternalLinkPropertiesForm: React.FC<ExternalLinkPropertiesFormProps> = (
	props: ExternalLinkPropertiesFormProps
) => {
	const {initialValues, onSubmit} = props;
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={ExternalLinkPropertiesFormSchema}
			onSubmit={onSubmit}
		>
			{formProps => <AutoCommitInnerForm {...props} {...formProps} />}
		</Formik>
	);
};

export default ExternalLinkPropertiesForm;
