import React, {useState, useCallback, useEffect, ChangeEvent} from "react";
import {Field, ErrorMessage} from "formik";

import {SelectOption} from "../../form/types";
import {Target} from "./LinkTarget.types";

import * as StyledForm from "../../form/StyledForm";
import NumericInput from "../numericInput/NumericInputComponent";
import {isEqual} from "../../form/utils";

export interface FormValues {
	target?: string;
	lightBox?: boolean;
	lightBoxWidth?: number;
	lightBoxHeight?: number;
}

export interface FormOptions {
	target: SelectOption[];
}

export interface FormProps {
	target: SelectOption[];
	values: FormValues;
	setTarget: (value: string) => void;
	setLightBox: (value: boolean) => void;
	setLightBoxWidth: (value: number) => void;
	setLightBoxHeight: (value: number) => void;
}

export const Form: React.FC<FormProps> = (props: FormProps): JSX.Element => {
	const {
		target,
		values,
		setTarget,
		setLightBox,
		setLightBoxWidth,
		setLightBoxHeight,
	} = props;

	const [targetAttr, setTargetAttr] = useState<string>(values.target);

	// Update lightbox based on current target selected
	useEffect(() => {
		const isLightBox = isEqual<FormValues, Target>(
			props,
			"target",
			Target.Lightbox
		);
		setLightBox(isLightBox);
		if (!isLightBox) {
			setLightBoxWidth(0);
			setLightBoxHeight(0);
		}
	}, [values.target]);

	// Update target based on selected
	useEffect(() => {
		setTarget(targetAttr !== Target.Custom ? targetAttr : "_blank");
	}, [targetAttr]);

	const handleTargetAttrChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) =>
			setTargetAttr(
				event.currentTarget.options[event.currentTarget.selectedIndex].value
			),
		[]
	);
	return (
		<>
			{/** Open link in */}
			<StyledForm.Group>
				<StyledForm.FieldWrapper>
					<StyledForm.Label>Open link in</StyledForm.Label>
					<StyledForm.Field>
						<Field
							as="select"
							options={target}
							disabled={false}
							value={targetAttr}
							onChange={handleTargetAttrChange}
						>
							{target.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</Field>
					</StyledForm.Field>
				</StyledForm.FieldWrapper>
				{/* Target */}
				<StyledForm.FieldWrapper>
					<StyledForm.Field>
						<Field
							name="target"
							value={values.target}
							type="text"
							disabled={targetAttr !== Target.Custom}
						/>
					</StyledForm.Field>
					<ErrorMessage name="target" />
				</StyledForm.FieldWrapper>
			</StyledForm.Group>
			{/** Lightbox options */}
			<StyledForm.Group>
				{/* Lightbox width */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="lightBoxWidth">
						Lightbox width
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="lightBoxWidth"
							value={values.lightBoxWidth}
							as={NumericInput}
							min={0}
							disabled={
								!isEqual<FormValues, Target>(props, "target", Target.Lightbox)
							}
							onChange={setLightBoxWidth}
						/>
					</StyledForm.Field>
					<ErrorMessage name="lightBoxWidth" />
				</StyledForm.FieldWrapper>
				{/* Lightbox height */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="lightBoxHeight">
						Lightbox height
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="lightBoxHeight"
							value={values.lightBoxHeight}
							as={NumericInput}
							min={0}
							disabled={
								!isEqual<FormValues, Target>(props, "target", Target.Lightbox)
							}
							onChange={setLightBoxHeight}
						/>
					</StyledForm.Field>
					<ErrorMessage name="lightBoxHeight" />
				</StyledForm.FieldWrapper>
			</StyledForm.Group>
		</>
	);
};

export default Form;
