import React, {useEffect, FunctionComponent} from "react";
import {Formik} from "formik";
import withAutoCommit from "../../form/withAutoCommit";
import {Form, FormOwnProps, FormValues} from "./Form";

const AutoCommitForm = withAutoCommit<FormOwnProps, FormValues>(Form);

export interface InternalLinkPropertiesFormProps extends FormOwnProps {
	/** Initial field values of the form.
	 * Even if your form is empty by default, you must initialize all fields with initial values.
	 */
	initialValues: FormValues;
	/** Updatable form state. Use when need to change form state from outside.
	 * If values are empty, only initial state wiil be applied
	 */
	values?: FormValues;
	/** Form submission handler */
	onSubmit?: (values: FormValues) => void;
}

export const InternalLinkPropertiesForm: FunctionComponent<InternalLinkPropertiesFormProps> = (
	props: InternalLinkPropertiesFormProps
) => {
	const {initialValues, values, options, onSubmit} = props;

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit}>
			{formProps => {
				const {
					initialErrors,
					initialTouched,
					values: formValues,
					errors,
					touched,
					dirty,
					isValid,
					isSubmitting,
					isValidating,
					submitCount,
					setValues,
					setFieldValue,
					setStatus,
					setErrors,
					setSubmitting,
					setTouched,
					setFieldError,
					setFieldTouched,
					validateField,
					validateForm,
					resetForm,
					submitForm,
					setFormikState,
					handleBlur,
					handleChange,
					handleReset,
					handleSubmit,
					getFieldProps,
					getFieldHelpers,
					getFieldMeta,
					registerField,
					unregisterField,
				} = formProps;

				useEffect(() => {
					if (values) {
						setValues(values, false);
					}
				}, [values]);

				return (
					<AutoCommitForm
						options={options}
						initialValues={initialValues}
						initialErrors={initialErrors}
						initialTouched={initialTouched}
						values={formValues}
						errors={errors}
						touched={touched}
						dirty={dirty}
						isValid={isValid}
						isSubmitting={isSubmitting}
						isValidating={isValidating}
						submitCount={submitCount}
						setFieldValue={setFieldValue}
						setValues={setValues}
						setStatus={setStatus}
						setErrors={setErrors}
						setSubmitting={setSubmitting}
						setTouched={setTouched}
						setFieldError={setFieldError}
						setFieldTouched={setFieldTouched}
						validateField={validateField}
						validateForm={validateForm}
						resetForm={resetForm}
						submitForm={submitForm}
						setFormikState={setFormikState}
						handleBlur={handleBlur}
						handleChange={handleChange}
						handleReset={handleReset}
						handleSubmit={handleSubmit}
						getFieldProps={getFieldProps}
						getFieldHelpers={getFieldHelpers}
						getFieldMeta={getFieldMeta}
						registerField={registerField}
						unregisterField={unregisterField}
					/>
				);
			}}
		</Formik>
	);
};

export default InternalLinkPropertiesForm;
