import styled from "@emotion/styled";

export const FormPanel = styled.div`
	flex: 0 0 520px;
	padding: 10px;
	box-sizing: border-box;
`;

export const Form = styled.div`
	display: inline-flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
`;

export const Group = styled.div`
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	height: 520px;
`;

export const Footer = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	align-items: center;
`;
