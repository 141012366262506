import {css} from "@emotion/core";
import {lighten} from "polished";
import {Type, Feedback, Size, Shape} from "./Button.types";

const getColorDependsOnType = ({
	theme,
	feedback,
	disabled,
	type = "primary",
}) => {
	let color = theme.colors[type].medium;
	let hoverColor = theme.colors[type].light;
	if (disabled) {
		color = theme.colors.disabled.light;
		hoverColor = theme.colors.disabled.light;
	} else if (feedback) {
		color = theme.colors.feedback[feedback];
		hoverColor = lighten(0.1, theme.colors.feedback[feedback]);
	}
	return {
		color,
		hoverColor,
	};
};

const getDefaultButtonStyles = (
	theme,
	feedback: Feedback,
	disabled: boolean
) => {
	const {color, hoverColor} = getColorDependsOnType({
		theme,
		feedback,
		disabled,
	});
	return css`
		color: ${color};
		background: ${theme.colors.bg.primary};
		border: 1px solid ${color};
		&:hover {
			border-color: ${hoverColor};
			color: ${hoverColor};
		}
	`;
};

const getCircleSize = (size: Size) => {
	let val;
	switch (size) {
		case "small":
			val = "40px";
			break;
		case "middle":
			val = "50px";
			break;
		case "large":
			val = "70px";
			break;
	}
	return css`
		height: ${val};
		width: ${val};
	`;
};

const styles = theme => {
	return {
		commonStyles: () => css`
			outline: none;
			display: flex;
			justify-content: center;
			align-items: center;
			background: transparent;
			border: none;
			padding: 0;
			cursor: pointer;
			border-radius: 5px;
		`,
		sizeStyles: (size: Size) => {
			switch (size) {
				case "small":
					return css`
						font-size: ${theme.typography.size.sm};
						padding: ${theme.spacing.xs} ${theme.spacing.xs};
					`;
				case "middle":
					return css`
						font-size: ${theme.typography.size.lg};
						padding: ${theme.spacing.sm} ${theme.spacing.sm};
					`;
				case "large":
					return css`
						font-size: ${theme.typography.size.xl};
						padding: ${theme.spacing.md} ${theme.spacing.md};
					`;
				default:
					return css``;
			}
		},
		typeStyles: (type: Type, feedback: Feedback, isButtonDisabled: boolean) => {
			const {color, hoverColor} = getColorDependsOnType({
				theme,
				feedback,
				disabled: isButtonDisabled,
				type: "primary",
			});
			const {
				color: secondaryColor,
				hoverColor: secondaryHoverColor,
			} = getColorDependsOnType({
				theme,
				feedback,
				disabled: isButtonDisabled,
				type: "secondary",
			});
			switch (type) {
				case "primary":
					return [
						css`
							color: ${theme.colors.text};
							background: ${color};
							border: none;
							&:hover {
								background: ${hoverColor};
							}
						`,
					];
				case "secondary":
					return css`
						color: ${theme.colors.text};
						background: ${secondaryColor};
						&:hover {
							background: ${secondaryHoverColor};
						}
					`;
				case "default":
					return getDefaultButtonStyles(theme, feedback, isButtonDisabled);
				case "dashed":
					return [
						getDefaultButtonStyles(theme, feedback, isButtonDisabled),
						css`
							border-style: dashed;
						`,
					];
				case "link":
					return css`
						padding: 0;
						border: none;
						background: none;
						color: ${theme.colors.link.default};
						&:hover {
							color: ${theme.colors.link.hover};
						}
					`;
				case "transparent":
					return css`
						border: none;
						background: none;
					`;
				default:
					return css``;
			}
		},
		shapeStyles: (shape: Shape, size: Size) => {
			switch (shape) {
				case "circle":
					return [
						getCircleSize(size),
						css`
							border-radius: 50%;
							overflow: hidden;
							padding: 0;
						`,
					];
				case "sharp":
					return css`
						border-radius: 0;
					`;
				default:
					return css``;
			}
		},
		iconStyles: () => css`
			span {
				display: flex;
			}
			span + span {
				margin-left: ${theme.spacing.xs};
			}
		`,
	};
};
export default styles;
