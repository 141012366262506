import {Theme} from "./theme";
import {generateCommonColorSpectrum, generateLinkSpectrum} from "./utility";

const theme: Theme = {
	colors: {
		primary: generateCommonColorSpectrum("#f12c2c"),
		secondary: generateCommonColorSpectrum("#3b3ba8"),
		disabled: generateCommonColorSpectrum("#848383"),
		link: generateLinkSpectrum("#699ed5"),
		bg: {
			primary: "#fff",
			secondary: "#bfbcbc",
		},
		feedback: {
			success: "#52a72f",
			error: "#f56403",
			warning: "#e7a11a",
			default:"#848383",
			info: "#1ea7fd"
		},
		text: "#ffffff",
	},
	spacing: {
		xxxs: "2px",
		xxs: "4px",
		xs: "8px",
		sm: "12px",
		md: "16px",
		lg: "24px",
		xl: "32px",
		xxl: "40px",
	},
	typography: {
		size: {
			xxxs: "6px",
			xxs: "8px",
			xs: "10px",
			sm: "12px",
			md: "16px",
			lg: "18px",
			xl: "26px",
			xxl: "32px",
			xxxl: "42px",
		},
		family: "Arial",
	},
};

export default theme;
