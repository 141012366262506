import * as React from "react";
import {Component} from "react";
import Creatable from "react-select/creatable";

import debounce from "lodash.debounce";
import {
	CreatableValue,
	CreatableStyles,
	CreatableSelectOption,
	ChangeAction,
	ChangeInputAction,
	CreatableChangeActions,
	CreatableChangeInputActions,
} from "./CreatableSelectTypes";
import {Constants} from "../util/constants";
import {ActionMeta} from "react-select";

export interface CreatableSelectComponentProps {
	/** The current value of the input */
	value: CreatableValue;
	/** The value to use upon initialization when no value is provided. */
	defaultValue: number;
	/** The options to display  */
	options: Array<CreatableSelectOption>;
	/** The label that appears on the right side of the input in place of the drop down arrow. */
	label?: string;
	/** A map of styles used by react-select. Documentation can be found at https://react-select.com/styles */
	styles: CreatableStyles | undefined;
	/** The wait period in milliseconds until the onChange event is emitted. */
	debounceDelay?: number;
	/** Event called when there is a change */
	onChange(value: number): void;
}

interface CreatableSelectComponentState {
	creatableValue: CreatableSelectOption;
	isDebounce: boolean;
	isFocused: boolean;
}

/**
 * This function returns clear number string without other symbols
 */
const getNumber = (inputValue: string) => {
	let parsedValue = inputValue;
	// A JavaScript RegExp object is stateful.
	Constants.POSITIVE_NUMBER_REGEX.lastIndex = 0;
	const numberRegex = Constants.POSITIVE_NUMBER_REGEX;
	const regexResult = numberRegex.exec(parsedValue.toString());
	if (regexResult && regexResult.length > 0) {
		parsedValue = regexResult[0];
	}
	return parsedValue;
};

/**
 * Make creatable select option from value
 */
const toCreatableValue = (
	inputValue: CreatableValue
): CreatableSelectOption => {
	return {value: inputValue, label: inputValue};
};

class CreatableSelectComponent extends Component<
	CreatableSelectComponentProps,
	CreatableSelectComponentState
> {
	static defaultProps: Pick<
		CreatableSelectComponentProps,
		"value" | "defaultValue" | "debounceDelay" | "styles" | "options"
	> = {
		value: 14,
		defaultValue: 14,
		debounceDelay: 500,
		styles: undefined,
		options: [],
	};

	protected select: Creatable<CreatableSelectOption>;

	protected debouncedSave: ReturnType<typeof debounce>;

	constructor(props: CreatableSelectComponentProps) {
		super(props);

		// We have own controlled `creatableValue` prop
		// to have a chance control user input, and accept numeric input only
		// also we control the moment when we debouncing the input changes
		// additionally we manage isFocused state for creatable input
		this.state = {
			creatableValue: toCreatableValue(this.getValue(null, true)),
			isDebounce: false,
			isFocused: false,
		};

		// Debounce
		this.debouncedSave = debounce(
			this.handleSaveChanges,
			this.props.debounceDelay
		);
	}

	static getDerivedStateFromProps(
		nextProps: CreatableSelectComponentProps,
		prevState: CreatableSelectComponentState
	) {
		const nextPropsVal = nextProps.value
			? getNumber(nextProps.value.toString())
			: null;

		// Update state value, if props has been changed and not <debounce> now
		if (
			nextPropsVal &&
			!prevState.isDebounce &&
			prevState.creatableValue &&
			nextPropsVal !== prevState.creatableValue.value
		) {
			return {creatableValue: toCreatableValue(nextPropsVal)};
		}

		return null;
	}

	handleSaveChanges = (newValue: CreatableValue) => {
		const {onChange} = this.props;
		const {isFocused} = this.state;

		onChange(parseFloat(this.getValue(newValue).toString()));
		// Stop debounce state and update input value and cursor, if under focused right now
		this.setState({isDebounce: false}, () => {
			if (isFocused) {
				this.handleFocus();
			}
		});
	};

	saveChanges = (newValue: CreatableValue) => {
		// Start debouncing
		this.setState({isDebounce: true});
		// and call debounced function
		this.debouncedSave(newValue);
	};

	/**
	 * Disable client side filtering by react-select because
	 * we want to show all variants to user always
	 */
	filterOption = (
		_option: {
			label: string;
			value: string;
			data: Object;
		},
		_input: string
	): boolean | null => {
		return true;
	};

	/**
	 * Creatable select option value change (selected from list)
	 */
	handleChange = (
		newValue: CreatableSelectOption,
		actionMeta: ChangeAction
	) => {
		const {onChange} = this.props;

		if (
			actionMeta.action === CreatableChangeActions.SELECT_OPTION &&
			newValue !== undefined
		) {
			onChange(parseFloat(this.getValue(newValue.value).toString()));
		}
	};

	/**
	 * Creatable select input value change
	 */
	handleInputChange = (inputValue: string, actionMeta: ChangeInputAction) => {
		const {value} = this.state.creatableValue;

		if (
			actionMeta.action === CreatableChangeInputActions.INPUT_CHANGE &&
			inputValue !== undefined
		) {
			// Clear input string, accept numbers only (ex 31.4)
			const val = this.getValue(inputValue).toString();

			if (inputValue === "" || inputValue === "0") {
				// Correctly handle empty value, but not save it (the previous saved val will be used if left)

				this.setState({creatableValue: toCreatableValue(inputValue)});
				this.saveChanges(value);
			} else if (val === inputValue.toString() || inputValue === "") {
				// Compare clear and input value strings.
				// if they have unacceptable difference

				// If not - changed value is correct
				// So, save it, and parent cb

				this.saveChanges(inputValue);
				this.setState({
					creatableValue: toCreatableValue(inputValue),
					isDebounce: true,
				});
			} else {
				// In case values are different

				// This tricky method is used there, to correct input value
				// if it contains wrong symbols\charters.
				// It changes value back, to the last correct saved
				this.setState({creatableValue: toCreatableValue(val)}, () =>
					this.handleFocus()
				);
			}
		}
	};

	/**
	 * This method is used there, to prevent of show the "Create new..." option
	 */
	isValidNewOption = (
		_inputValue: string,
		_selectValue: Array<CreatableSelectOption>,
		_selectOptions: Array<CreatableSelectOption>
	) => {
		return false;
	};

	formatCreateLabel = (inputValue: CreatableValue) => {
		return <span>{inputValue}</span>;
	};

	/**
	 * This basic method is used, for value get
	 * Priority: inputValue > state.value > value > defaultValue
	 */
	getValue = (
		inputValue?: CreatableValue,
		strict?: boolean
	): CreatableValue => {
		const {defaultValue, value} = this.props;

		let parsedValue =
			inputValue || (!strict && (inputValue === "" || inputValue === "0"))
				? inputValue.toString()
				: this.state
				? this.state.creatableValue.value
				: value;
		parsedValue =
			parsedValue || (!strict && (parsedValue === "" || parsedValue === "0"))
				? parsedValue.toString()
				: defaultValue.toString();

		return getNumber(parsedValue);
	};

	/**
	 * Make input value editable on click hook
	 */
	handleFocus = () => {
		const {isFocused} = this.state;
		if (this.select) {
			const option = toCreatableValue(this.getValue());
			const actionMeta: ActionMeta<CreatableSelectOption> = {
				action: "create-option",
				option,
			};

			this.select.onChange(option, actionMeta);
			// Set focused state property, if not already set
			if (!isFocused) {
				this.setState({isFocused: true});
			}
		}
	};

	onBlur = (_event: React.FocusEvent) => {
		this.setState({isFocused: false});
		this.verifyValueBeforeExit();
	};

	/**
	 * General recommendation for mobile\tablet devices
	 */
	handleMenuClose = () => {
		this.verifyValueBeforeExit();
	};

	/**
	 * Change dropdown indicator to the label, if required
	 */
	dropdownIndicator = () => (
		<div style={{padding: "8px 12px"}}>{this.props.label}</div>
	);

	componentWillUnmount() {
		this.verifyValueBeforeExit();
		// Prevent memory leak with async setState call
		this.debouncedSave.cancel();
	}

	/**
	 * Reset value back, if it's empty or zero
	 */
	verifyValueBeforeExit = () => {
		const {isDebounce} = this.state;
		const {onChange, value} = this.props;
		const actualValue = this.state.creatableValue.value;

		// Prevent empty value
		if (actualValue === "" || actualValue.toString() === "0") {
			// Use previously saved correct value
			onChange(parseFloat(this.getValue(value).toString()));
		} else if (isDebounce && actualValue) {
			// Save debouncing value immediately, before component will be unmounted
			onChange(parseFloat(this.getValue(actualValue).toString()));
		}
	};

	render() {
		const {defaultValue, label, styles, options} = this.props;
		const {creatableValue} = this.state;
		const components = label
			? {DropdownIndicator: this.dropdownIndicator, IndicatorSeparator: null}
			: {};

		return (
			<Creatable
				value={creatableValue}
				inputValue={creatableValue.value.toString()}
				defaultValue={toCreatableValue(defaultValue)}
				onChange={this.handleChange}
				onInputChange={this.handleInputChange}
				options={options}
				/* eslint-disable-next-line react/jsx-no-bind */
				ref={ref => (this.select = ref)}
				blurInputOnSelect={false}
				onMenuClose={this.handleMenuClose}
				onFocus={this.handleFocus}
				isValidNewOption={this.isValidNewOption}
				formatCreateLabel={this.formatCreateLabel}
				className={"select-creatable-element"}
				classNamePrefix="select-creatable"
				styles={styles}
				components={components}
				filterOption={this.filterOption}
				onBlur={this.onBlur}
			/>
		);
	}
}

export {CreatableSelectComponent as default, CreatableSelectComponent};
