import * as React from "react";
import {Component} from "react";
import {
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot,
} from "react-beautiful-dnd";
import {DroppableId, DraggableItemAction} from "./DraggableDndTypes";
import DraggablePortalComponent from "./DraggablePortalComponent";
import DraggableOutsideComponent from "./DraggableOutsideComponent";
import ActionIconComponent from "./ActionIconComponent";

interface DraggableRowProps {
	id: string;
	description: string;
	icon: string;
	index: number;
	columnId: DroppableId;
	isSelected: boolean;
	changeIcon?: string;
	actions?: DraggableItemAction[];
	touchedId?: string;
	ignoredOutsideClickClassName?: string;
	onDragSelect: (itemId: string) => void;
	onDragRelease?: (itemId: string) => void;
	onDragCancel: (itemId: string) => void;
	onRemoveItem?: (
		itemId: string,
		columnId: DroppableId,
		sourceIndex: number
	) => void;
	onActionClick?: (id: string, type: string) => void;
}

interface DraggableRowState {
	isSelected: boolean;
}

const rowStyle = {
	margin: "5px 0",
	padding: "0 7px",
	color: "#afafaf",
	border: "1px solid #c6c6c6",
	backgroundColor: "#333333",
	fontSize: "13px",
	cursor: "pointer",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	minHeight: "28px",
} as React.CSSProperties;

const rowInfoStyle = {
	display: "flex",
	alignItems: "center",
} as React.CSSProperties;

const smallIconStyle = {
	width: "18px",
	height: "18px",
	borderRadius: "4px",
	position: "absolute",
} as React.CSSProperties;

const removeIconStyle = {
	cursor: "pointer",
	fontSize: "21px",
	paddingLeft: "8px",
} as React.CSSProperties;

const textStyle = {
	marginLeft: "24px",
} as React.CSSProperties;

const maxDescriptionLength = 25;

class DraggableRowComponent extends Component<
	DraggableRowProps,
	DraggableRowState
> {
	static defaultProps = {
		changeIcon: "",
		onRemoveItem: () => undefined,
		onActionClick: () => undefined,
	};

	isMoved = false;
	isClicked = false;

	constructor(props: DraggableRowProps) {
		super(props);

		this.state = {
			isSelected: false,
		};
	}

	static getDerivedStateFromProps(
		nextProps: DraggableRowProps,
		prevState: DraggableRowState
	) {
		// Handle case where we switch from state `isSelected` to props `isSelected`
		return prevState.isSelected && nextProps.isSelected
			? {
					isSelected: false,
			  }
			: null;
	}

	onMouseUp = () => {
		const {id, onDragRelease} = this.props;
		if (!this.isMoved) {
			onDragRelease && onDragRelease(id);
		}

		this.isClicked = false;
		this.isMoved = false;
	};

	/**
	 * On item cancel select event
	 * click outside of selected item is used
	 */
	onOutsideDraggableClick = () => {
		const {id} = this.props;
		if (this.props.isSelected || this.state.isSelected) {
			this.setState({
				isSelected: false,
			});
			this.props.onDragCancel(id);
		}
		this.isClicked = false;
		this.isMoved = false;
	};

	/**
	 * On item remove icon click
	 */
	onRemoveClick = () => {
		const {onRemoveItem, id, index, columnId} = this.props;
		onRemoveItem(id, columnId, index);
	};

	/**
	 * First 25 characters in the text requirement
	 */
	getDescription = () => {
		const {description} = this.props;
		return description.length < maxDescriptionLength
			? description
			: `${description.substring(0, maxDescriptionLength)}...`;
	};

	/**
	 * On item select event
	 * onMouseDown at droppable
	 */
	onMouseDown = () => {
		this.isClicked = true;
		this.setState({
			isSelected: true,
		});
		this.props.onDragSelect(this.props.id);
	};

	onMouseMove = () => {
		if (this.isClicked) {
			this.isMoved = true;
		}
	};

	componentDidUpdate() {
		if (this.props.touchedId === this.props.id) {
			this.isClicked = false;
		}
	}

	renderChangeListIcon = () => {
		const {changeIcon} = this.props;

		return changeIcon ? (
			<span
				style={removeIconStyle}
				onClick={this.onRemoveClick}
				dangerouslySetInnerHTML={{__html: changeIcon}}
			/>
		) : null;
	};

	renderWidgetActions = () => {
		const {id, actions, onActionClick} = this.props;

		return actions && actions.length > 0
			? actions.map(
					({enabled, iconEnabled, iconDisabled, type, isActionDisabled}) => (
						<ActionIconComponent
							key={type}
							id={id}
							enabled={enabled}
							iconEnabled={iconEnabled}
							iconDisabled={iconDisabled}
							type={type}
							isActionDisabled={isActionDisabled}
							onActionClick={onActionClick}
						/>
					)
			  )
			: null;
	};

	renderItem = (
		provided: DraggableProvided,
		snapshot: DraggableStateSnapshot
	): React.ReactElement<HTMLElement> => {
		const {isSelected, icon} = this.props;
		const draggableRowStyle =
			isSelected || this.state.isSelected
				? {...rowStyle, backgroundColor: "#8c01ff", color: "white"}
				: rowStyle;

		return (
			<DraggablePortalComponent provided={provided} snapshot={snapshot}>
				<div
					style={draggableRowStyle}
					onMouseUp={this.onMouseUp}
					onMouseDown={this.onMouseDown}
					onMouseMove={this.onMouseMove}
				>
					<div style={rowInfoStyle}>
						<div
							style={{
								...smallIconStyle,
								backgroundImage: `url(${icon})`,
								filter: "brightness(120%)",
							}}
						/>
						<span style={textStyle}>{this.getDescription()}</span>
					</div>
					<div style={rowInfoStyle}>
						{this.renderWidgetActions()}
						{this.renderChangeListIcon()}
					</div>
				</div>
			</DraggablePortalComponent>
		);
	};

	renderComponent() {
		// I attempted to update the draggableId to see if it fixed some caching, but that doesn't seem to be the case.
		const {index, isSelected, id} = this.props;
		const draggableClass = `${
			isSelected || this.state.isSelected ? "editor-draggable-item" : ""
		}`;
		return (
			<div className={draggableClass}>
				<Draggable key={id} draggableId={id} index={index}>
					{this.renderItem}
				</Draggable>
			</div>
		);
	}

	render() {
		return (
			<DraggableOutsideComponent
				outsideHandler={this.onOutsideDraggableClick}
				ignoredOutsideClickClassName={this.props.ignoredOutsideClickClassName}
			>
				{this.renderComponent()}
			</DraggableOutsideComponent>
		);
	}
}

export {DraggableRowComponent as default};
