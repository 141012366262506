import styled from "@emotion/styled";

const Navigator = styled.div`
	padding: 8px 4px;
`;

const Pagination = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	& > select {
		height: 28px;
		flex: 1 1 auto;
		margin-left: 6px;
		margin-right: 6px;
	}
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background: gray;
	margin: 4px 0;
`;

const RowLayout = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	padding: 0 8px;
	&:not(:last-child) {
		margin-bottom: 4px;
	}
`;

const Thumb = styled.div`
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row nowrap;
	width: 100%;
`;

export default {
	Navigator,
	Divider,
	Pagination,
	RowLayout,
	Thumb,
};
