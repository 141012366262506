import React, {useState, useCallback, useEffect, useRef} from "react";
import useImage from "use-image";

import {
	Icon,
	Button,
	CheckBox,
	ColorPicker,
	ActionArea,
	Image,
} from "../../components";
import Slider from "../../components/slider/SliderComponent";

import {Page} from "./Navigator.types";
import {
	ActionAreaHandles,
	PolygonShape,
	ShapeColors,
} from "../contentActionArea/ContentActionArea.types";

import Styled from "./Navigator.styles";

const checkShapeInsideArea = (
	shapePoints: Array<number>,
	areaWidth: number,
	areaHeight: number
): Array<number> => {
	const x0 = 0;
	const y0 = 0;
	const x1 = areaWidth;
	const y1 = areaHeight;

	const points = [...shapePoints];

	if (points[0] < x0) {
		points[4] = points[6] -= points[0];
		points[0] = points[2] = x0;
	}
	if (points[1] < y0) {
		points[5] = points[3] -= points[1];
		points[1] = points[7] = y0;
	}
	if (points[4] > x1) {
		points[0] = points[2] -= points[4] - x1;
		points[4] = points[6] = x1;
	}
	if (points[5] > y1) {
		points[1] = points[7] -= points[5] - y1;
		points[5] = points[3] = y1;
	}
	return points;
};

export interface NavigatorProps {
	/** A list of thumbnails for each page */
	pages: Page[];
	/** An index of current page */
	pageIdx: number;
	/** Callback that is fired when a user moves to a page */
	onChangePage?: (pageIdx: number) => void;
	/** HEX highlight color */
	color: string;
	/** Callback that is fired when a user changes color */
	onChangeColor?: (highlightColor: string) => void;
	/** Indicates whether to use highlight color or not */
	initialIsHighlightColor: boolean;
	/** Callback that is fired when a user switch color highlighter */
	onChangeIsHighlightColor?: (isHighlightColor: boolean) => void;
	/**
	 * Width of the source image
	 * Source image - image which should be controlled by navigator
	 */
	sourceImageWidth: number;
	/**
	 * Height of the source image
	 * Source image - image which should be controlled by navigator
	 * */
	sourceImageHeight: number;
	/** Width of the image container */
	containerWidth: number;
	/** Height of the image container */
	containerHeight: number;
	/** Base scale index of source image */
	baseScaleIndexOfSourceImage: number;
	/** Width of the image preview */
	imageWidth: number;
	/** Height of the image preview */
	imageHeight: number;
	/** Is it supports zoom? */
	hasZoom: boolean;
	/** Zoom position */
	zoom?: number;
	/** Min zoom */
	zoomFrom?: number;
	/** Max zoom */
	zoomTo?: number;
	/** Styles for zoom on preview */
	zoomStyles?: ShapeColors;
	/** Callback that is fired when a user changes zoom */
	onChangeZoom?: (zoom: number) => void;
	/** Preview position */
	previewPosition: Array<number>;
	/** Callback that is fired when a preview position was changed */
	onChangePreviewPosition?: (previewPosition: Array<number>) => void;
	/**
	 * Callback that is fired when a user changes zoom
	 * This is a coefficient how many times we need to increase/decrease source image
	 * */
	onChangeScaleIndex: (index: number) => void;
}

export const NavigatorComponent: React.FunctionComponent<NavigatorProps> = (
	props: NavigatorProps
): JSX.Element => {
	const {
		pageIdx,
		pages,
		onChangePage,
		color,
		onChangeColor,
		initialIsHighlightColor,
		onChangeIsHighlightColor,
		imageWidth,
		imageHeight,
		sourceImageWidth,
		sourceImageHeight,
		containerWidth,
		containerHeight,
		hasZoom,
		zoom,
		zoomFrom,
		zoomTo,
		zoomStyles = {
			fill: "#8080803d",
			stroke: "red",
			opacity: 1,
			strokeWidth: 1,
		},
		onChangeZoom,
		previewPosition,
		onChangePreviewPosition,
		baseScaleIndexOfSourceImage = 0,
		onChangeScaleIndex,
	} = props;

	const [isHighlightColor, setIsHighlightColor] = useState<boolean>(
		initialIsHighlightColor
	);

	const actionRef = useRef<ActionAreaHandles>(null);

	// Pagination
	const canMoveNextPage = pageIdx < pages.length - 1;
	const canMovePrevPage = pageIdx > 0;

	// Image zoom
	const [image] = useImage(pages[pageIdx]?.path);


	const safeSetCurrentPageIdx = useCallback(
		(currentPageIdx) => {
			let nextPageIdx = currentPageIdx;
			const firstPage = 0;
			const lastPage = pages.length - 1;
			if (nextPageIdx < firstPage) {
				nextPageIdx = firstPage;
			}
			if (nextPageIdx > lastPage) {
				nextPageIdx = lastPage;
			}
			if (nextPageIdx !== pageIdx) {
				onChangePage(nextPageIdx);
			}
		},
		[pageIdx, pages.length, onChangePage]
	);

	const setCurrentPage = useCallback(
		(event: React.ChangeEvent<HTMLSelectElement>) =>
			safeSetCurrentPageIdx(event.target.selectedIndex),
		[safeSetCurrentPageIdx]
	);

	const movePrevPage = useCallback(() => {
		safeSetCurrentPageIdx(pageIdx - 1);
	}, [pageIdx, safeSetCurrentPageIdx]);

	const moveNextPage = useCallback(() => {
		safeSetCurrentPageIdx(pageIdx + 1);
	}, [pageIdx, safeSetCurrentPageIdx]);

	const formatZoom = useCallback((value: number): string => `${value}%`, []);

	const handleZoomChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) =>
			onChangeZoom(Number(event.target.value)),
		[onChangeZoom]
	);

	const handleShapeMoved = useCallback(
		(shapeToChange: PolygonShape) => {
			onChangePreviewPosition(shapeToChange.points);
		},
		[onChangePreviewPosition]
	);

	useEffect(() => {
		const zoomIndex = (zoom - zoomFrom) / (zoomTo - zoomFrom) / 2;
		const scaleIndex = baseScaleIndexOfSourceImage + zoomIndex;
		const shape: PolygonShape = actionRef.current?.getShapes()[0];
		const [x, y] = shape?.points || [0, 0];
		const sourceScaledWidth = sourceImageWidth * scaleIndex;
		const sourceScaledHeight = sourceImageHeight * scaleIndex;
		const deltaWidth = Math.min(sourceScaledWidth, containerWidth) / sourceScaledWidth;
		const deltaHeight = Math.min(sourceScaledHeight, containerHeight) / sourceScaledHeight;
		const midWidth = imageWidth / 2;
		const midHeight = imageHeight / 2;
		const dWidth = midWidth * deltaWidth;
		const dHeight = midHeight * deltaHeight;
		const offsetX = x - (midWidth - dWidth);
		const offsetY = y - (midHeight - dHeight);
		const points = [
			Math.trunc(midWidth - dWidth + offsetX),
			Math.trunc(midHeight - dHeight + offsetY),
			Math.trunc(midWidth - dWidth + offsetX),
			Math.trunc(midHeight + dHeight + offsetY),
			Math.trunc(midWidth + dWidth + offsetX),
			Math.trunc(midHeight + dHeight + offsetY),
			Math.trunc(midWidth + dWidth + offsetX),
			Math.trunc(midHeight - dHeight + offsetY),
		];
		const safePoints = checkShapeInsideArea(points, imageWidth, imageHeight);
		onChangePreviewPosition(safePoints);
		onChangeScaleIndex(scaleIndex);
	}, [
		imageWidth,
		imageHeight,
		sourceImageWidth,
		sourceImageHeight,
		containerWidth,
		containerHeight,
		onChangePreviewPosition,
		onChangeScaleIndex,
		baseScaleIndexOfSourceImage,
		zoom,
		zoomFrom,
		zoomTo,
		pageIdx,
		actionRef,
	]);

	useEffect(() => {
		if (onChangeIsHighlightColor) {
			onChangeIsHighlightColor(isHighlightColor);
		}
	}, [isHighlightColor, onChangeIsHighlightColor]);

	return (
		<Styled.Navigator>
			<Styled.Thumb>
				{hasZoom ? (
					<ActionArea
						ref={actionRef}
						transformMode="dragOnly"
						onShapeChanged={handleShapeMoved}
						shapes={[
							{
								id: "navigatorPreview",
								points: previewPosition,
								...zoomStyles,
							},
						]}
						image={image}
						scaleIndex={1}
						fitImageToStage={true}
						width={imageWidth}
						height={imageHeight}
					/>
				) : (
					<Image
						src={pages[pageIdx]?.path}
						width={imageWidth}
						height={imageHeight}
					/>
				)}
			</Styled.Thumb>
			<Styled.Divider />
			{hasZoom && (
				<>
					<Styled.RowLayout>
						<Slider
							value={zoom}
							min={zoomFrom}
							max={zoomTo}
							step={1}
							disabled={false}
							showValueOutput={true}
							formatValueOutput={formatZoom}
							onChange={handleZoomChange}
						/>
					</Styled.RowLayout>
					<Styled.Divider />
				</>
			)}
			<Styled.RowLayout>
				<Styled.Pagination>
					<Button
						icon={<Icon name={"arrow_left"} />}
						onClick={movePrevPage}
						size="small"
						type="secondary"
						disabled={!canMovePrevPage}
					/>
					<select value={pages[pageIdx]?.id} onChange={setCurrentPage}>
						{pages.map((page: Page) => (
							<option key={page.id} value={page.id}>
								{page.name}
							</option>
						))}
					</select>
					<Button
						icon={<Icon name={"arrow_right"} />}
						onClick={moveNextPage}
						size="small"
						type="secondary"
						disabled={!canMoveNextPage}
					/>
				</Styled.Pagination>
			</Styled.RowLayout>
			<Styled.RowLayout>
				<CheckBox
					disabled={false}
					checked={isHighlightColor}
					label="Highlight color:"
					onChange={setIsHighlightColor}
				/>
				<ColorPicker
					color={color}
					presetColors={[]}
					disableAlpha={true}
					onChange={onChangeColor}
				/>
			</Styled.RowLayout>
		</Styled.Navigator>
	);
};

export default NavigatorComponent;
