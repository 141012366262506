/*
Ideally we would use https://emotion.sh/docs/@emotion/babel-preset-css-prop to replace the JSX pragma requirement. The
issue is that ts-loader processes this file before it reaches babel. To get ts-loader to play nice we need to change the
tsconfig value "jsx" from "react" to "preserve" https://github.com/emotion-js/emotion/issues/1748. But this change
appears to blow up something in our stack. I believe this may be caused by react-docgen-typescript-loader, but I haven't
looked farther into it.
 */
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import {jsx} from "@emotion/core";
import {useTheme} from "emotion-theming";
import React, {Children} from "react";
import {WrapperProps, SubmenuInnerProps} from "./Navigation.types";

import styles from "./Navigation.styles";
import {renderChild} from "./Navigation.utils";

const Wrapper: React.FC<WrapperProps> = ({
	float,
	type,
	mode,
	size,
	handleClick,
	children,
	activeItems,
}) => {
	const commonProps = {type, mode, size, float, activeItems, handleClick};
	const theme = useTheme();
	const {wrapperStyles} = styles(theme);
	const childrenWithProps = Children.map<
		{},
		React.FunctionComponentElement<SubmenuInnerProps>
	>(
		children,
		(
			child: React.FunctionComponentElement<SubmenuInnerProps>
		): React.ReactNode =>
			renderChild({
				childType: child.type,
				childProps: child.props,
				commonProps,
				isNested: false,
			})
	);
	return <ul css={[wrapperStyles({type, float})]}>{childrenWithProps}</ul>;
};

export {Wrapper as default, Wrapper};
