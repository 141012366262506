import React, {Component, CSSProperties, ChangeEvent} from "react";
import debounce from "lodash.debounce";
export interface TextAreaProps {
	/** An id attribute to allow the textarea to be associated with a <label> element for accessibility purposes */
	id?: string;
	/** A name attribute to set the name
	 * of the associated data point submitted to the server when the form is submitted. */
	name?: string;
	/** rows and cols attributes to allow you to specify an exact size for the textarea to take.
	 * Setting these is a good idea for consistency, as browser defaults can differ. */
	rows?: number;
	cols?: number;
	/** Allow the textArea to be resized via handle */
	resize?: boolean;
	/** This Boolean attribute indicates that the user cannot interact with the control.
	 * If this attribute is not specified, the control inherits its setting from the containing element, for example
	 * fieldset; if there is no containing element when the disabled attribute is set, the control is enabled.*/
	disabled?: boolean;
	/** The maximum number of characters (UTF-16 code units) that the user can enter.
	 * If this value isn't specified, the user can enter an unlimited number of characters. */
	maxLength?: number;
	/** The minimum number of characters (UTF-16 code units) required that the user should enter.*/
	minLength?: number;
	/** A hint to the user of what can be entered in the control.
	 * Carriage returns or line-feeds within the placeholder text must be treated as
	 * line breaks when rendering the hint.  */
	placeholder?: string;
	/** This Boolean attribute indicates that the user cannot modify the value of the control. Unlike
	 * the disabled attribute, the readonly attribute does not prevent the user from clicking or
	 * selecting in the control. The value of a read-only control is still submitted with the form.*/
	readOnly?: boolean;
	/** This attribute specifies that the user must fill in a value before submitting a form. */
	required?: boolean;
	/** Specifies whether the textarea is subject to spell checking by the underlying browser/OS.*/
	spellCheck?: boolean;
	/** Indicates how the control wraps text. */
	wrap?: "hard" | "soft" | "off";
	/** Initialize the textArea with a value specified here. */
	initialValue?: string;
	/** Custom styles applied directly to the textArea element, resize style from resize prop applied last */
	style?: CSSProperties;
	/** Receive the onChange event (if text content changed) by providing
	 * a function that accepts the ChangeEvent<HTMLTextAreaElement> - NOTE: This function will be debounced by default
	 * see: debounceContentChangeHandler property for options.*/
	contentChangeHandler?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	/** The function provided via contentChangeHandler is debounced, here you may specify the time (in milliseconds)
	 * to wait for events to stop firing before a value is returned. */
	contentChangeHandlerWait?: number;
	/** The maximum time contentChangeHandler is allowed to be delayed before it's invoked. */
	contentChangeHandlerMaxWait?: number;
}

interface TextAreaState {
	value: string;
}

type DebouncedContentChangeHandler = ((
	event: ChangeEvent<HTMLTextAreaElement>
) => void) &
	_.Cancelable;

class TextAreaComponent extends Component<TextAreaProps, TextAreaState> {
	static defaultProps: TextAreaProps = {
		rows: 5,
		cols: 30,
		resize: true,
		contentChangeHandlerWait: 200,
		contentChangeHandlerMaxWait: 500,
		initialValue: "",
	};
	debouncedContentChangeHandler: DebouncedContentChangeHandler;
	constructor(props: TextAreaProps) {
		super(props);
		const {
			contentChangeHandler,
			contentChangeHandlerWait,
			contentChangeHandlerMaxWait,
			initialValue,
		} = this.props;
		this.state = {value: initialValue};
		const debounceOptions: _.DebounceSettings = {
			...(contentChangeHandlerMaxWait && {
				maxWait: contentChangeHandlerMaxWait,
			}),
		};
		if (contentChangeHandler) {
			this.debouncedContentChangeHandler = debounce(
				contentChangeHandler,
				contentChangeHandlerWait,
				debounceOptions
			);
		}
	}
	onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const {contentChangeHandler} = this.props;
		if (contentChangeHandler && event.target.value !== this.state.value) {
			event.persist();
			this.debouncedContentChangeHandler(event);
		}
		this.setState({value: event.target.value});
	};

	componentWillUnmount() {
		if (this.debouncedContentChangeHandler) {
			this.debouncedContentChangeHandler.flush();
		}
	}

	render() {
		const {
			id,
			name,
			rows,
			cols,
			resize,
			disabled,
			maxLength,
			minLength,
			placeholder,
			readOnly,
			required,
			spellCheck,
			wrap,
			style: styleProp,
		} = this.props;
		const style: CSSProperties = {
			...styleProp,
			...(resize === false && {resize: "none"}),
		};
		return (
			<textarea
				id={id}
				name={name}
				rows={rows}
				cols={cols}
				disabled={disabled}
				maxLength={maxLength}
				minLength={minLength}
				placeholder={placeholder}
				readOnly={readOnly}
				required={required}
				spellCheck={spellCheck}
				wrap={wrap}
				style={style}
				value={this.state.value}
				onChange={this.onChangeHandler}
			/>
		);
	}
}

export {TextAreaComponent as default, TextAreaComponent};
