import React, {
	FunctionComponent,
	ChangeEvent,
	DragEvent,
	useRef,
	useState,
} from "react";
import {FormInput} from "../../form/form.types";
import {Button} from "../button/Button";
import {Icon} from "../icon/Icon";
import {UploadContainer, DropZone, FilesList, File} from "./FileUpload.styles";

export interface FileUploadProps extends FormInput {
	/** Disable input */
	disabled: boolean;
	/** Sets the value of the accept attribute of the file upload button */
	accept?: string;
	/** Sets or returns whether a user is allowed to select more than one file in the file upload field */
	multiple: boolean;
	/** Allows drag files to a specific area, to upload */
	withDragDrop: boolean;
}

export const FileUpload: FunctionComponent<FileUploadProps> = (
	props: FileUploadProps
): JSX.Element => {
	const {
		id,
		name,
		label,
		disabled,
		accept,
		multiple,
		withDragDrop,
		onChange,
	} = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const [files, setFiles] = useState<File[]>(null);
	const [dragProgress, setDragProgress] = useState<boolean>(false);

	function openFileUpload() {
		if (inputRef) {
			const {current} = inputRef;
			if (current) {
				current.click();
			}
		}
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setFiles(Array.from(event.currentTarget.files));
		if (onChange) {
			onChange(event);
		}
	}

	function handleDrop(event: DragEvent<HTMLDivElement>) {
		event.preventDefault();
		event.stopPropagation();
		const transferFiles = Array.from(event.dataTransfer.files);
		setFiles(multiple ? transferFiles : transferFiles.slice(0, 1));
		setDragProgress(false);
	}

	function handleDragLeave(event: DragEvent<HTMLDivElement>) {
		event.preventDefault();
		event.stopPropagation();
		setDragProgress(false);
	}

	function handleDragEnter(event: DragEvent<HTMLDivElement>) {
		event.preventDefault();
		event.stopPropagation();
		setDragProgress(true);
	}

	function handleDragOver(event: DragEvent<HTMLDivElement>) {
		event.preventDefault();
		event.stopPropagation();
	}

	return (
		<UploadContainer>
			<Button
				icon={<Icon name={"cloud_upload"} />}
				size="small"
				type={dragProgress ? "dashed" : "default"}
				label={label}
				disabled={disabled}
				onClick={openFileUpload}
			/>
			{withDragDrop && !disabled && (
				<DropZone
					onClick={openFileUpload}
					onDrop={handleDrop}
					onDragLeave={handleDragLeave}
					onDragEnter={handleDragEnter}
					onDragOver={handleDragOver}
				/>
			)}
			{!!files && !!files.length && (
				<FilesList>
					{files.map(
						(file: File): JSX.Element => {
							return (
								<File key={file.name}>
									<Icon name={"attach_file"} />
									<div>{file.name}</div>
								</File>
							);
						}
					)}
				</FilesList>
			)}
			<input
				type="file"
				id={id}
				name={name}
				disabled={disabled}
				accept={accept}
				multiple={multiple}
				style={{display: "none"}}
				ref={inputRef}
				onChange={disabled ? null : handleChange}
			/>
		</UploadContainer>
	);
};

export default FileUpload;
