import * as React from "react";
import {
	withDebounceChange,
	DebounceChangeProps,
} from "../../hoc/withDebounceChange/withDebounceChange";

export interface SliderProps extends DebounceChangeProps<HTMLInputElement> {
	/** Specifies ID of the slider. Can not be changed. Initialized during initial rendering */
	id?: string;
	/** A name attribute to set the name
	 * of the associated data point submitted to the server when the form is submitted. */
	name?: string;
	/** The value of the slider */
	value: number;
	/** The minimum the slider can slide to */
	min: number;
	/** The maximum the slider can slide to */
	max: number;
	/** The step through values */
	step: number;
	/** Makes slider not interactable */
	disabled: boolean;
	/** Used to display formatted value output. By default displays value as is */
	formatValueOutput?: (value?: number) => string;
	/** To display value output or not */
	showValueOutput: boolean;
	/** Specifies the label for the slider if not empty */
	label?: string;
	/** Callback that is fired when a user changes the slider's value. */
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface SliderState {
	value: number;
}

export class SliderComponent extends React.Component<SliderProps, SliderState> {
	static defaultProps: Pick<
		SliderProps,
		| "id"
		| "name"
		| "value"
		| "min"
		| "max"
		| "step"
		| "disabled"
		| "showValueOutput"
		| "label"
	> = {
		id: "",
		name: "",
		value: 0,
		min: 0,
		max: 100,
		step: 1,
		disabled: false,
		showValueOutput: true,
		label: "",
	};
	constructor(props: SliderProps) {
		super(props);
		const {value} = props;
		this.state = {
			value,
		};
	}

	componentDidUpdate(prevProps: SliderProps): void {
		const {value} = this.props;
		if (prevProps.value !== value) {
			this.setState({value});
		}
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {
			target: {value: valueStr},
		} = event;
		const value: number = Number(valueStr);
		this.setState({value});
		const {onChange} = this.props;
		if (onChange) {
			onChange(event);
		}
	};

	render(): JSX.Element {
		const {
			id,
			name,
			min,
			max,
			step,
			disabled,
			formatValueOutput,
			showValueOutput,
			label,
		} = this.props;
		const {value} = this.state;
		return (
			<div>
				<label htmlFor={id}>
					{label && <span>{label}</span>}
					<input
						type="range"
						id={id}
						name={name}
						min={min}
						max={max}
						step={step}
						value={value}
						disabled={disabled}
						onChange={disabled ? null : this.handleChange}
					/>
					{showValueOutput && (
						<output htmlFor={id}>
							{formatValueOutput ? formatValueOutput(value) : value}
						</output>
					)}
				</label>
			</div>
		);
	}
}

export const Slider = withDebounceChange<
	HTMLInputElement,
	SliderProps,
	SliderState
>(SliderComponent);

export default Slider;
