import * as React from "react";
import {
	DroppableProvided,
	DroppableStateSnapshot,
	Droppable,
} from "react-beautiful-dnd";
import {DraggableItem, DroppableId} from "./DraggableDndTypes";
import DraggableRowComponent from "./DraggableRowComponent";

export interface DraggableColumnProps {
	label?: string;
	items: DraggableItem[];
	columnId: DroppableId;
	selectedId?: string;
	changeIcon?: string;
	touchedId?: string;
	ignoredOutsideClickClassName?: string;
	draggableWrapperClassName?: string;
	onDragStart: (itemId: string) => void;
	onDragRelease?: (itemId: string) => void;
	onRemoveItem?: (
		itemId: string,
		columnId: DroppableId,
		sourceIndex: number
	) => void;
	onDragCancel: (itemId: string) => void;
	onActionClick?: (id: string, type: string) => void;
}

const labelStyle: React.CSSProperties = {
	fontWeight: 600,
	color: "#c6c6c6",
	fontSize: "14px",
};

const columnStyle: React.CSSProperties = {
	paddingBottom: "10px",
};

const droppableStyle: React.CSSProperties = {
	minHeight: "28px",
};

class DraggableColumnComponent extends React.Component<DraggableColumnProps> {
	renderItems = (
		provided: DroppableProvided,
		snapshot: DroppableStateSnapshot
	): React.ReactElement<HTMLElement> => {
		const {
			items,
			onDragStart,
			onRemoveItem,
			onDragCancel,
			onActionClick,
			onDragRelease,
			selectedId,
			columnId,
			changeIcon,
			touchedId,
			ignoredOutsideClickClassName,
			draggableWrapperClassName = "",
		} = this.props;
		const styleCurrent = snapshot.isDraggingOver
			? {backgroundColor: "#626262", ...droppableStyle}
			: {...droppableStyle};

		return (
			<div
				className={draggableWrapperClassName}
				{...provided.droppableProps}
				ref={provided.innerRef}
				style={styleCurrent}
			>
				{items &&
					items.map((item, index) => {
						const {id, description, icon, actions} = item;
						return (
							<DraggableRowComponent
								key={id}
								id={id}
								index={index}
								description={description}
								icon={icon}
								actions={actions}
								columnId={columnId}
								onDragSelect={onDragStart}
								onDragRelease={onDragRelease}
								onDragCancel={onDragCancel}
								onRemoveItem={onRemoveItem}
								onActionClick={onActionClick}
								isSelected={selectedId && selectedId === id}
								changeIcon={changeIcon}
								touchedId={touchedId}
								ignoredOutsideClickClassName={ignoredOutsideClickClassName}
							/>
						);
					})}
				{provided.placeholder}
			</div>
		);
	};

	render() {
		const {columnId, label} = this.props;
		return (
			<div style={columnStyle}>
				{label && <div style={labelStyle}>{label}</div>}
				<Droppable droppableId={columnId}>{this.renderItems}</Droppable>
			</div>
		);
	}
}

export {DraggableColumnComponent as default};
