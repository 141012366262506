import React, {Component} from "react";

import {DropdownValue, ActionProps} from "./DropdownValue";
import {DropdownPresenter} from "./DropdownPresenter";

interface DropdownComponentProps {
	values: Array<DropdownValue>;
	fixedValues: Array<DropdownValue>;
	onChange?: (
		newValues: Array<DropdownValue>,
		previousValues: Array<DropdownValue>
	) => void;
	isDisabled: boolean;
}

interface DropdownComponentState {
	selectedValues: Array<DropdownValue>;
}

const isSelected = (dropdownValue: DropdownValue) => {
	return dropdownValue.isSelected;
};

class DropdownComponent extends Component<
	DropdownComponentProps,
	DropdownComponentState
> {
	constructor(props: DropdownComponentProps) {
		super(props);
		const {fixedValues, values} = this.props;

		this.state = {
			selectedValues: fixedValues.concat(values.filter(isSelected)),
		};
	}

	onChangeHandler = (
		values: Array<DropdownValue>,
		actionMetadata: ActionProps
	) => {
		const {action} = actionMetadata;
		const {fixedValues, onChange} = this.props;

		const isClearAction = action === "clear";
		const newValues = isClearAction ? fixedValues : values;

		if (onChange) {
			const previousValues = this.state.selectedValues;
			onChange(newValues, previousValues);
		}

		this.setState({selectedValues: newValues});
	};

	render() {
		const {isDisabled, fixedValues, values} = this.props;
		const {selectedValues} = this.state;
		return (
			<DropdownPresenter
				isDisabled={isDisabled}
				values={values}
				selectedValues={selectedValues}
				fixedValues={fixedValues}
				onChange={this.onChangeHandler}
			/>
		);
	}
}

export {DropdownComponent as default, DropdownComponent};
