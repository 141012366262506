import React, {FunctionComponent, SyntheticEvent} from "react";

export interface ImageProps {
	/** Image source. */
	src: string;
	/** Alt text for the image. */
	alt?: string;
	height?: number;
	width?: number;
	/** The function specified in both onLoad and onError will receive the triggering event. */
	onLoad?: (event: SyntheticEvent<HTMLImageElement, Event>) => void;
	onError?: (event: SyntheticEvent<HTMLImageElement, Event>) => void;
}

export const Image: FunctionComponent<ImageProps> = ({
	src,
	alt,
	height,
	width,
	onLoad: onLoadFn,
	onError: onErrorFn,
}) => {
	return (
		<img
			src={src}
			alt={alt}
			onLoad={onLoadFn}
			onError={onErrorFn}
			height={height}
			width={width}
		/>
	);
};

export default Image;
