import React, {useCallback, SyntheticEvent, ChangeEvent} from "react";
import {FormikProps, Form as FormikForm, Field, ErrorMessage} from "formik";

import NumericInput from "../numericInput/NumericInputComponent";
import Radio from "../radio/RadioComponent";
import Button from "../button/Button";
import * as StyledForm from "../../form/StyledForm";
import * as Styled from "./ProductLinkPropertiesForm.styles";

import {BaseLink} from "../linkPropertiesForm/LinkPropertiesForm.types";

export interface FormValues extends BaseLink {}

export interface FormOwnProps {
	/** Indicates that there is a pending searching of product */
	searchingProduct?: boolean;
	/** Handler to start search product */
	searchProduct: (magid?: number, productID?: string) => void;
}

export type FormProps = FormOwnProps & FormikProps<FormValues>;

export const Form: React.FC<FormProps> = (props: FormProps): JSX.Element => {
	const {values, setFieldValue, searchProduct, searchingProduct} = props;

	// Update number field value since onchange receive number, not event
	const handleNumberChange = (name: string): ((value: number) => void) =>
		useCallback(value => setFieldValue(name, value), []);

	const handleChangeHasProduct = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const checked = event.target.checked;
			setFieldValue("useProductDatabase", !checked);
			setFieldValue("hasProduct", checked);
		},
		[]
	);

	const handleChangeUseProductDatabase = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const checked = event.target.checked;
			setFieldValue("useProductDatabase", checked);
			setFieldValue("hasProduct", !checked);
		},
		[]
	);
	const handleSearchProduct = useCallback(
		(e?: SyntheticEvent) => {
			if (e) {
				e.preventDefault();
			}
			searchProduct(values.magid, values.productID);
		},
		[searchProduct, values.magid, values.productID]
	);

	return (
		<FormikForm>
			{/** Product source */}
			<StyledForm.Group>
				<StyledForm.FieldWrapper>
					<StyledForm.Label>Product source</StyledForm.Label>
					<StyledForm.Group>
						<Radio
							id="hasProduct"
							name="hasProduct"
							label="Manual"
							disabled={false}
							checked={values.hasProduct}
							onChange={handleChangeHasProduct}
						/>
						<Radio
							id="useProductDatabase"
							name="useProductDatabase"
							label="Product database"
							disabled={false}
							checked={values.useProductDatabase}
							onChange={handleChangeUseProductDatabase}
						/>
					</StyledForm.Group>
				</StyledForm.FieldWrapper>
			</StyledForm.Group>
			{/** Product ID */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="productID">Product ID</StyledForm.Label>
				<StyledForm.Field>
					<Field name="productID" type="text" disabled={false} />
				</StyledForm.Field>
				{values.useProductDatabase && (
					<Styled.FieldActions>
						<Button
							onClick={handleSearchProduct}
							size="small"
							shape="sharp"
							type="default"
							label={searchingProduct ? "Searching..." : "Search"}
							disabled={!values.productID}
						/>
					</Styled.FieldActions>
				)}
				<ErrorMessage name="productID" />
			</StyledForm.FieldWrapper>
			{/** Product name */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="itemName">Product name</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="itemName"
						type="text"
						disabled={values.useProductDatabase}
					/>
				</StyledForm.Field>
				<ErrorMessage name="itemName" />
			</StyledForm.FieldWrapper>
			{/** Product description */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="description">
					Product description
				</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="description"
						type="text"
						disabled={values.useProductDatabase}
					/>
				</StyledForm.Field>
				<ErrorMessage name="description" />
			</StyledForm.FieldWrapper>
			{/* Product price */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="price">Product price</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="price"
						as={NumericInput}
						min={0}
						disabled={values.useProductDatabase}
						onChange={handleNumberChange("price")}
					/>
				</StyledForm.Field>
				<ErrorMessage name="price" />
			</StyledForm.FieldWrapper>
		</FormikForm>
	);
};

export default Form;
