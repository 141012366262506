import React, {useCallback} from "react";
import {DraggableItemAction} from "./DraggableDndTypes";

interface ActionIconComponentProps extends DraggableItemAction {
	id: string;
	onActionClick: (id: string, type: string) => void;
}

const actionIconStyle = {
	width: "18px",
	height: "18px",
	cursor: "pointer",
	marginLeft: "7px",
} as React.CSSProperties;

const ActionIconComponent = (props: ActionIconComponentProps) => {
	const {
		id,
		enabled,
		iconEnabled,
		iconDisabled,
		type,
		isActionDisabled,
		onActionClick,
	} = props;

	const onMouseDown = useCallback(
		(e: React.MouseEvent<HTMLImageElement>) => {
			e.stopPropagation();

			if (!isActionDisabled) {
				onActionClick(id, type);
			}
		},
		[id, type]
	);

	return (
		<img
			src={enabled ? iconEnabled : iconDisabled}
			style={{
				...actionIconStyle,
				cursor: isActionDisabled ? "not-allowed" : "pointer",
			}}
			onMouseDown={onMouseDown}
		/>
	);
};

export default ActionIconComponent;
