import React from "react";
import ReactDOM from "react-dom";
import {DraggableProvided, DraggableStateSnapshot} from "react-beautiful-dnd";

const portal: HTMLElement = document.createElement("div");

if (!document.body) {
	throw new Error("body not ready for portal creation!");
}

document.body.appendChild(portal);

interface DraggablePortalProps {
	provided: DraggableProvided;
	snapshot: DraggableStateSnapshot;
	children: React.ReactElement<HTMLElement>;
}

const DraggablePortalComponent: React.FunctionComponent<DraggablePortalProps> = props => {
	const provided = props.provided;
	const snapshot = props.snapshot;
	const usePortal = snapshot.isDragging;

	const child = (
		<div
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
		>
			{props.children}
		</div>
	);

	if (!usePortal) {
		return child;
	}

	// if dragging - put the item in a portal
	// This is known issue and solution
	// https://github.com/atlassian/react-beautiful-dnd/issues/192
	return ReactDOM.createPortal(child, portal);
};

export {DraggablePortalComponent as default};
