/*
Ideally we would use https://emotion.sh/docs/@emotion/babel-preset-css-prop to replace the JSX pragma requirement. The
issue is that ts-loader processes this file before it reaches babel. To get ts-loader to play nice we need to change the
tsconfig value "jsx" from "react" to "preserve" https://github.com/emotion-js/emotion/issues/1748. But this change
appears to blow up something in our stack. I believe this may be caused by react-docgen-typescript-loader, but I haven't
looked farther into it.
 */
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import {jsx} from "@emotion/core";
import {useTheme} from "emotion-theming";
import * as React from "react";
import styles from "./Button.styles";
import {Type, Feedback, Size, Shape} from "./Button.types";

export interface ButtonProps {
	label?: string;
	type?: Type;
	feedback?: Feedback;
	size?: Size;
	shape?: Shape;
	disabled?: boolean;
	icon?: JSX.Element;
	onClick(): void;
}

const Button: React.FunctionComponent<ButtonProps> = ({
	label,
	size = "middle",
	type = "primary",
	feedback,
	shape,
	disabled,
	icon,
	onClick,
}) => {
	const theme = useTheme();
	const {
		commonStyles,
		sizeStyles,
		typeStyles,
		shapeStyles,
		iconStyles,
	} = styles(theme);
	const isButtonDisabled = disabled;
	return (
		<button
			css={[
				commonStyles(),
				sizeStyles(size),
				typeStyles(type, feedback, isButtonDisabled),
				shapeStyles(shape, size),
				icon ? iconStyles() : null,
			]}
			disabled={isButtonDisabled}
			onClick={onClick}
		>
			{icon && <span>{icon}</span>}
			{label && (shape !== "circle" || !icon) && <span>{label}</span>}
		</button>
	);
};

export {Button as default, Button};
