import React from "react";
import SubmenuComponent, {Submenu} from "./NavigationSubmenu";
import ItemComponent, {Item} from "./NavigationItem";

export const renderChild = ({childType, childProps, commonProps, isNested}) => {
	const {type, mode, size, float, activeItems, handleClick} = commonProps;
	if (childType === Submenu) {
		const {id, title, icon, children} = childProps;
		return (
			<SubmenuComponent
				id={id}
				title={title}
				icon={icon}
				children={children}
				type={type}
				mode={mode}
				float={float}
				size={size}
				activeItems={activeItems}
				handleClick={handleClick}
				isNested={isNested}
			/>
		);
	}
	if (childType === Item) {
		const {id, icon, children} = childProps;
		return (
			<ItemComponent
				id={id}
				children={children}
				icon={icon}
				type={type}
				mode={mode}
				float={float}
				size={size}
				activeItems={activeItems}
				handleClick={handleClick}
				isNested={isNested}
			/>
		);
	}
	return null;
};
