import * as React from "react";
import {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	ForwardRefRenderFunction,
} from "react";
import Konva from "konva";
import {Rect} from "react-konva";
import {DrawShapeHandles, DrawShapeProps} from "./ContentActionArea.types";

interface PositionInterface {
	x: number;
	y: number;
	width: number;
	height: number;
}
const DrawRectangleArea: ForwardRefRenderFunction<
	DrawShapeHandles,
	DrawShapeProps
	> = (
	{
		onFinish,
		drawingOpacity,
		drawingStrokeWidth,
		drawingStrokeColor,
		drawingFillColor,
		dash,
	},
	ref
) => {
	const [position, setPosition] = useState<PositionInterface | undefined>(
		undefined
	);
	const [isFinished, setIsFinished] = useState(false);

	useImperativeHandle(ref, () => ({
		clear: () => {
			setPosition(undefined);
		},
		handleMouseDown: (event: Konva.KonvaEventObject<MouseEvent>) => {
			setIsFinished(false);
			const stage = event.target.getStage();
			const transform = stage.getAbsoluteTransform().copy();
			transform.invert();
			const {x, y} = transform.point(stage.getPointerPosition());
			setPosition({
				x,
				y,
				width: 0,
				height: 0,
			});
		},
		handleMouseMove: (event: Konva.KonvaEventObject<MouseEvent>) => {
			if (position) {
				const {x: startX, y: startY} = position;
				const stage = event.target.getStage();
				const transform = stage.getAbsoluteTransform().copy();
				transform.invert();
				const {x, y} = transform.point(stage.getPointerPosition());
				const width = x - startX;
				const height = y - startY;
				setPosition({
					x: startX,
					y: startY,
					width,
					height,
				});
			}
		},
		handleMouseUp: () => {
			setIsFinished(true);
		},
	}));

	useEffect(() => {
		if (isFinished && position) {
			const {x, y, width, height} = position;
			if (width === 0 || height === 0) {
				return;
			}
			onFinish([x, y, x, y + height, x + width, y + height, x + width, y]);
			setIsFinished(false);
			setPosition(undefined);
		}
	}, [isFinished, position, onFinish]);

	return (
		position && (
			<Rect
				fill={drawingFillColor}
				stroke={drawingStrokeColor}
				opacity={drawingOpacity}
				strokeWidth={drawingStrokeWidth}
				width={position.width}
				height={position.height}
				dash={dash}
				x={position.x}
				y={position.y}
			/>
		)
	);
};

export default forwardRef(DrawRectangleArea);
