import React, {CSSProperties, FunctionComponent} from "react";

import Select, {Styles} from "react-select";

import {DropdownValue, ActionProps} from "./DropdownValue";

interface DropdownPresenterData<T> {
	data: T;
}

interface DropdownPresenterProps {
	values: Array<DropdownValue>;
	selectedValues: Array<DropdownValue>;
	fixedValues: Array<DropdownValue>;
	onChange: (value: any, actionMetadata: ActionProps) => void;
	isDisabled: boolean;
}

const isFixed = (
	dropdownValue: DropdownValue,
	fixedUsers: Array<DropdownValue>
) => {
	return fixedUsers.indexOf(dropdownValue) !== -1;
};

const DropdownPresenter: FunctionComponent<DropdownPresenterProps> = (
	props: DropdownPresenterProps
) => {
	const {selectedValues, fixedValues, values, onChange, isDisabled} = props;

	const styles: Styles = {
		multiValueRemove: (
			base: CSSProperties,
			state: DropdownPresenterData<DropdownValue>
		): CSSProperties => {
			// Spread the base css minus the hover to remove it
			const {
				alignItems,
				borderRadius,
				backgroundColor,
				display,
				paddingLeft,
				paddingRight,
				boxSizing,
			} = base;
			return isFixed(state.data, fixedValues)
				? {display: "none"}
				: {
						alignItems,
						borderRadius,
						backgroundColor,
						display,
						paddingLeft,
						paddingRight,
						boxSizing,
				  };
		},
	};

	return (
		<Select
			value={selectedValues}
			isDisabled={isDisabled}
			isClearable={false}
			isMulti={true}
			styles={styles}
			name="colors"
			className="zmags-dropdown"
			classNamePrefix="zmags-dropdown"
			onChange={onChange}
			options={values}
		/>
	);
};

export {DropdownPresenter as default, DropdownPresenter};
