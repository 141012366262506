import * as React from "react";
import Radio from "../radio/RadioComponent";

export interface RadioGroupItemProps {
	/**	Specifies ID of the radio. Can not be changed. Initialized during initial rendering */
	id?: string;
	/** Disable radio */
	disabled: boolean;
	/** Specifies the label for the radio if value is not empty */
	label?: string;
	/** Defines the unique value associated with each radio button */
	value: string;
}

/**
 * To render items for radio group use this placeholder.
 * Radio group renders Radio for each placeholder with name, checked state based on current value of the Radio group.
 */
export const RadioGroupItemComponent: React.FunctionComponent<RadioGroupItemProps> = () => (
	<></>
);

export interface RadioGroupProps {
	/** The radio group must have share the same name to be treated as a group */
	name: string;
	/** The string to use as the value of the radio when submitting the form, if the radio is currently toggled on */
	value: string;
	/** Radio buttons in a group */
	children: React.FunctionComponentElement<RadioGroupItemProps>[];
	/** The callback function that is triggered when the state changes */
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface RadioGroupState {
	value: string;
}

export class RadioGroupComponent extends React.Component<
	RadioGroupProps,
	RadioGroupState
> {
	static Radio: React.FunctionComponent<
		RadioGroupItemProps
	> = RadioGroupItemComponent;

	constructor(props: RadioGroupProps) {
		super(props);
		const {value} = props;
		this.state = {
			value,
		};
	}

	componentDidUpdate(prevProps: RadioGroupProps): void {
		const {value} = this.props;
		if (prevProps.value !== value) {
			this.setState({value});
		}
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {
			target: {checked, value},
		} = event;
		if (checked) {
			this.setState({value});
		}
		const {onChange} = this.props;
		if (onChange) {
			onChange(event);
		}
	};

	render(): JSX.Element {
		const {children, name} = this.props;
		const {value} = this.state;
		return (
			<>
				{React.Children.map<
					{},
					React.FunctionComponentElement<RadioGroupItemProps>
				>(
					children,
					(
						radio: React.FunctionComponentElement<RadioGroupItemProps>
					): React.ReactNode => {
						if (!radio || radio.type !== RadioGroupItemComponent) {
							return null;
						}
						const {props} = radio;
						const {value: radioValue} = props;
						return (
							<Radio
								{...props}
								name={name}
								checked={value === radioValue}
								onChange={this.handleChange}
							/>
						);
					}
				)}
			</>
		);
	}
}

export default RadioGroupComponent;
