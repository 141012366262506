import * as React from "react";
import {
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	ForwardRefRenderFunction,
} from "react";
import Konva from "konva";
import {Line, Rect} from "react-konva";
import {DrawShapeHandles, DrawShapeProps} from "./ContentActionArea.types";

const DrawPolygonArea: ForwardRefRenderFunction<
	DrawShapeHandles,
	DrawShapeProps & {scaleIndex: number}
> = (
	{
		onFinish,
		drawingOpacity,
		drawingStrokeWidth,
		drawingStrokeColor,
		drawingFillColor,
		scaleIndex,
	},
	ref
) => {
	const [curMousePos, setCurMousePos] = useState([0, 0]);
	const [points, setPoints] = useState([]);
	const [isMouseOverStartPoint, setMouseOverStartPoint] = useState(false);
	const [isFinished, setIsFinished] = useState(false);
	const cornerDotsScale = scaleIndex < 1 ? scaleIndex + 1 : 1;
	const getMousePos = (stage: Konva.Stage) => {
		const transform = stage.getAbsoluteTransform().copy();
		transform.invert();
		const {x, y} = transform.point(stage.getPointerPosition());
		return [x, y];
	};

	useImperativeHandle(ref, () => ({
		clear: () => {
			setPoints([]);
		},
		handleMouseDown: (event: Konva.KonvaEventObject<MouseEvent>) => {
			const stage = event.target.getStage();
			const mousePos = getMousePos(stage);
			if (isFinished) {
				return;
			}
			if (isMouseOverStartPoint && points.length >= 3) {
				setIsFinished(true);
			} else {
				setPoints([...points, mousePos]);
				return;
			}
		},
		handleMouseMove: (event: Konva.KonvaEventObject<MouseEvent>) => {
			const stage = event.target.getStage();
			const mousePos = getMousePos(stage);
			if (isMouseOverStartPoint && points.length >= 3) {
				const [x, y] = points;
				setCurMousePos([x, y]);
			} else {
				setCurMousePos(mousePos);
			}

			return;
		},
		handleMouseUp: () => {
			return;
		},
	}));

	const handleMouseOverStartPoint = () => {
		if (isFinished || points.length < 3) {
			return;
		}
		setMouseOverStartPoint(true);
	};
	const handleMouseOutStartPoint = () => {
		setMouseOverStartPoint(false);
	};

	useEffect(() => {
		if (isFinished) {
			onFinish(points.reduce((a, b) => a.concat(b), []));
		}
		setPoints([]);
		setCurMousePos([0, 0]);
		setMouseOverStartPoint(false);
		setIsFinished(false);
	}, [isFinished]);
	const flattenedPoints = points
		.concat(isFinished ? [] : curMousePos)
		.reduce((a, b) => a.concat(b), []);
	return (
		<>
			<Line
				points={flattenedPoints}
				stroke={drawingStrokeColor}
				opacity={drawingOpacity}
				strokeWidth={drawingStrokeWidth}
				closed={isFinished}
			/>
			{points.map((point, index) => {
				const width = 5;
				const x = point[0] - width / 2;
				const y = point[1] - width / 2;
				const isFirstPoint = index === 0;
				return (
					<Rect
						key={index}
						x={x}
						y={y}
						width={width}
						height={width}
						scale={{x: cornerDotsScale, y: cornerDotsScale}}
						fill={drawingFillColor}
						stroke={drawingStrokeColor}
						opacity={drawingOpacity}
						strokeWidth={drawingStrokeWidth}
						hitStrokeWidth={isFirstPoint ? 10 : 0}
						onMouseOver={isFirstPoint ? handleMouseOverStartPoint : undefined}
						onMouseOut={isFirstPoint ? handleMouseOutStartPoint : undefined}
					/>
				);
			})}
		</>
	);
};

export default forwardRef(DrawPolygonArea);
