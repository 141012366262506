export type CreatableValue = string | number;

export interface CreatableSelectOption {
	value: CreatableValue;
	label: CreatableValue;
}

export interface StylesMap {
	[key: string]: any;
}

export enum CreatableChangeInputActions {
	SET_VALUE = "set-value",
	INPUT_CHANGE = "input-change",
	INPUT_BLUR = "input-blur",
	MENU_CLOSE = "menu-close",
}

export interface ChangeInputAction {
	action:
		| CreatableChangeInputActions.SET_VALUE
		| CreatableChangeInputActions.INPUT_CHANGE
		| CreatableChangeInputActions.INPUT_BLUR
		| CreatableChangeInputActions.MENU_CLOSE;
}

export enum CreatableChangeActions {
	CLEAR = "clear",
	CREATE_OPTION = "create-option",
	DESESECT_OPTION = "deselect-option",
	POP_VALUE = "pop-value",
	REMOVE_VALUE = "remove-value",
	SELECT_OPTION = "select-option",
	SET_VALUE = "set-value",
}

export interface ChangeAction {
	action:
		| CreatableChangeActions.CLEAR
		| CreatableChangeActions.CREATE_OPTION
		| CreatableChangeActions.DESESECT_OPTION
		| CreatableChangeActions.POP_VALUE
		| CreatableChangeActions.REMOVE_VALUE
		| CreatableChangeActions.SELECT_OPTION
		| CreatableChangeActions.SET_VALUE;
}

export interface CreatableStyles {
	control: (providedStyles: StylesMap) => StylesMap;
	input: (providedStyles: StylesMap) => StylesMap;
	singleValue: (providedStyles: StylesMap) => StylesMap;
	indicatorsContainer: (providedStyles: StylesMap) => StylesMap;
	menu: (providedStyles: StylesMap) => StylesMap;
	menuList: (providedStyles: StylesMap) => StylesMap;
	option: (providedStyles: StylesMap, props: any) => StylesMap;
}
