import React, {
	useCallback,
	useState,
	useEffect,
	SyntheticEvent,
	ChangeEvent,
} from "react";
import {Formik, FormikProps, Form, Field, ErrorMessage} from "formik";

import withAutoCommit from "../../form/withAutoCommit";
import NumericInput from "../numericInput/NumericInputComponent";
import Button from "../button/Button";
import Icon from "../icon/Icon";
import Slider from "../slider/SliderComponent";
import * as StyledForm from "../../form/StyledForm";
import * as Styled from "./LinkPropertiesForm.styles";

import {object as YupObject, number as YupNumber} from "yup";
import {isEqual} from "../../form/utils";
import {SelectOption} from "../../form/types";
import {BaseLink} from "./LinkPropertiesForm.types";

import {
	TooltipType,
	Stretch,
	HorizontalAlignment,
	VerticalAlignment,
} from "./LinkPropertiesForm.types";

export interface FormValues extends BaseLink {}

export interface FormOptions {
	tooltipType: SelectOption[];
	tooltipMediaSource: SelectOption[];
	tooltipSuggestedText: SelectOption[];
	effect: SelectOption[];
	externalSource: SelectOption[];
	stretch: SelectOption[];
	horizontalAlignment: SelectOption[];
	verticalAlignment: SelectOption[];
}

interface OtherProps {
	options: FormOptions;
}

type InnerFormProps = OtherProps & FormikProps<FormValues>;

const InnerForm: React.FC<InnerFormProps> = (
	props: InnerFormProps
): JSX.Element => {
	const {options, values, setFieldValue} = props;
	const [constrainProportion, setConstrainProportion] = useState<boolean>(
		false
	);
	const [spanPages, setSpanPages] = useState<boolean>(false);
	const [tooltipSuggestedText, setTooltipSuggestedText] = useState<number>(-1);

	// Update number field value since onchange receive number, not event
	const handleNumberChange = (name: string): ((value: number) => void) =>
		useCallback(value => setFieldValue(name, value), []);

	// Update select field value
	const handleSelectChange = (
		name: string
	): ((event: ChangeEvent<HTMLSelectElement>) => void) =>
		useCallback(
			event =>
				setFieldValue(
					name,
					Number(
						event.currentTarget.options[event.currentTarget.selectedIndex].value
					)
				),
			[]
		);

	const handleSelectHorizontalAlignment = handleSelectChange(
		"horizontalAlignment"
	);

	const handleSelectVerticalAlignment = handleSelectChange("verticalAlignment");

	// Update tooltip options for media and text types
	const handleTooltipSuggestedTextChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) => {
			const value = Number(
				event.currentTarget.options[event.currentTarget.selectedIndex].value
			);
			const option = options.tooltipSuggestedText.find(
				(o: SelectOption) => o.value === value
			);
			setTooltipSuggestedText(Number(option.value));
			// Update tooltip text from suggestion
			setFieldValue("tooltip", option.value === -1 ? "" : option.label);
		},
		[options.tooltipSuggestedText]
	);

	// Update stretch options
	const handleStretchChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) => {
			const value =
				event.currentTarget.options[event.currentTarget.selectedIndex].value;
			const option = options.stretch.find(
				(o: SelectOption) => o.value === value
			);
			setFieldValue("stretch", option.value);
			// Update horizontal alignment to default if option is not allowed
			if (
				option.value === Stretch.Horizontal ||
				option.value === Stretch.Both
			) {
				setFieldValue("horizontalAlignment", HorizontalAlignment.None);
			}

			// Update vertical alignment to default if option is not allowed
			if (option.value === Stretch.Vertical || option.value === Stretch.Both) {
				setFieldValue("verticalAlignment", VerticalAlignment.None);
			}
		},
		[options.stretch]
	);

	const handleConstrainProportionChange = useCallback(
		(e?: SyntheticEvent) => {
			if (e) {
				e.preventDefault();
			}
			setConstrainProportion(!constrainProportion);
		},
		[constrainProportion]
	);

	const handleSpanPagesChange = useCallback(
		(event?: ChangeEvent<HTMLInputElement>) => {
			const {checked} = event.target;
			setSpanPages(checked);
		},
		[spanPages]
	);

	return (
		<Form>
			<StyledForm.Group>
				<StyledForm.Group style={{width: "auto"}}>
					<div>
						{/* Width */}
						<StyledForm.FieldWrapper>
							<StyledForm.Label htmlFor="width">Width</StyledForm.Label>
							<StyledForm.Field>
								<Field
									name="width"
									as={NumericInput}
									min={0}
									onChange={handleNumberChange("width")}
								/>
							</StyledForm.Field>
							<ErrorMessage name="width" />
						</StyledForm.FieldWrapper>
						{/* Height */}
						<StyledForm.FieldWrapper>
							<StyledForm.Label htmlFor="height">Height</StyledForm.Label>
							<StyledForm.Field>
								<Field
									name="height"
									as={NumericInput}
									min={0}
									onChange={handleNumberChange("height")}
								/>
							</StyledForm.Field>
							<ErrorMessage name="height" />
						</StyledForm.FieldWrapper>
					</div>
					{/* Constrain Proportion */}
					<div>
						{constrainProportion && <Styled.LockTop />}
						<Button
							icon={<Icon name={"lock"} />}
							onClick={handleConstrainProportionChange}
							size="small"
							shape="sharp"
							type="default"
						/>
						{constrainProportion && <Styled.LockBottom />}
					</div>
				</StyledForm.Group>
				<div>
					{/* Top */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="y">Top</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="y"
								as={NumericInput}
								min={0}
								onChange={handleNumberChange("y")}
							/>
						</StyledForm.Field>
						<ErrorMessage name="y" />
					</StyledForm.FieldWrapper>
					{/* Left */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="x">Left</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="x"
								as={NumericInput}
								min={0}
								onChange={handleNumberChange("x")}
							/>
						</StyledForm.Field>
						<ErrorMessage name="x" />
					</StyledForm.FieldWrapper>
				</div>
			</StyledForm.Group>
			{/** Tooltip type */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="tooltipType">Tooltip type</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="tooltipType"
						as="select"
						disabled={false}
						onChange={handleSelectChange("tooltipType")}
					>
						{options.tooltipType.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Field>
				</StyledForm.Field>
				<ErrorMessage name="tooltipType" />
			</StyledForm.FieldWrapper>
			{/** Options for Tooltip type: Media */}
			<div>
				{/** Media source */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="tooltipMediaSource">
						Media source
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="tooltipMediaSource"
							as="select"
							disabled={
								!isEqual<FormValues, TooltipType>(
									props,
									"tooltipType",
									TooltipType.Media
								)
							}
							onChange={handleSelectChange("tooltipMediaSource")}
						>
							{options.tooltipMediaSource.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</Field>
					</StyledForm.Field>
					<ErrorMessage name="tooltipMediaSource" />
				</StyledForm.FieldWrapper>
				{/** Media title */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="tooltipMediaTitle">
						Media title
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="tooltipMediaTitle"
							type="text"
							disabled={
								!isEqual<FormValues, TooltipType>(
									props,
									"tooltipType",
									TooltipType.Media
								)
							}
						/>
					</StyledForm.Field>
					<ErrorMessage name="tooltipMediaTitle" />
				</StyledForm.FieldWrapper>
				{/** Media description */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="tooltipMediaDescription">
						Media description
					</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="tooltipMediaDescription"
							type="text"
							disabled={
								!isEqual<FormValues, TooltipType>(
									props,
									"tooltipType",
									TooltipType.Media
								)
							}
						/>
					</StyledForm.Field>
					<ErrorMessage name="tooltipMediaDescription" />
				</StyledForm.FieldWrapper>
			</div>
			{/** Options for Tooltip type: Text */}
			<div>
				{/** Tooltip text */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label htmlFor="tooltip">Tooltip text</StyledForm.Label>
					<StyledForm.Field>
						<Field
							name="tooltip"
							type="text"
							disabled={
								!isEqual<FormValues, TooltipType>(
									props,
									"tooltipType",
									TooltipType.Text
								)
							}
						/>
					</StyledForm.Field>
					<ErrorMessage name="tooltip" />
				</StyledForm.FieldWrapper>
				{/** Select tooltip */}
				<StyledForm.FieldWrapper>
					<StyledForm.Label>Select tooltip</StyledForm.Label>
					<StyledForm.Field>
						<Field
							as="select"
							value={tooltipSuggestedText}
							disabled={
								!isEqual<FormValues, TooltipType>(
									props,
									"tooltipType",
									TooltipType.Text
								)
							}
							onChange={handleTooltipSuggestedTextChange}
						>
							{options.tooltipSuggestedText.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</Field>
					</StyledForm.Field>
				</StyledForm.FieldWrapper>
			</div>
			{/** Effect */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="effect">Effect</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="effect"
						as="select"
						disabled={false}
						onChange={handleSelectChange("effect")}
					>
						{options.effect.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Field>
				</StyledForm.Field>
				<ErrorMessage name="effect" />
			</StyledForm.FieldWrapper>
			{/** External source */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="externalSource">
					External source
				</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="externalSource"
						as="select"
						disabled={false}
						onChange={handleSelectChange("externalSource")}
					>
						{options.externalSource.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Field>
				</StyledForm.Field>
				<ErrorMessage name="externalSource" />
			</StyledForm.FieldWrapper>
			{/** Opacity */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label htmlFor="alpha">Opacity</StyledForm.Label>
				<StyledForm.Field>
					<Field
						name="alpha"
						as={Slider}
						disabled={false}
						min={0}
						max={100}
						step={1}
					/>
				</StyledForm.Field>
				<ErrorMessage name="alpha" />
			</StyledForm.FieldWrapper>
			{/** Span pages */}
			<StyledForm.FieldWrapper>
				<StyledForm.Label>Span pages</StyledForm.Label>
				<StyledForm.Field>
					<Field
						type="checkbox"
						disabled={false}
						checked={spanPages}
						onChange={handleSpanPagesChange}
					/>
				</StyledForm.Field>
			</StyledForm.FieldWrapper>
			{/** Span pages options */}
			{!!spanPages && (
				<div>
					{/** Page range */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label>Page range</StyledForm.Label>
						<Field
							as={NumericInput}
							disabled={true}
							min={0}
							max={100}
							step={1}
							value={values.fromPageNumber}
						/>
						<span>-</span>
						<Field
							as={NumericInput}
							disabled={true}
							min={0}
							max={100}
							step={1}
							value={values.toPageNumber}
						/>
					</StyledForm.FieldWrapper>
					{/** Stretch */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="stretch">Stretch</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="stretch"
								as="select"
								disabled={false}
								onChange={handleStretchChange}
							>
								{options.stretch.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Field>
						</StyledForm.Field>
						<ErrorMessage name="stretch" />
					</StyledForm.FieldWrapper>
					{/** Horizontal Alignment */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="horizontalAlignment">
							Horizontal Alignment
						</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="horizontalAlignment"
								as="select"
								disabled={
									isEqual<FormValues, Stretch>(
										props,
										"stretch",
										Stretch.Horizontal
									) ||
									isEqual<FormValues, Stretch>(props, "stretch", Stretch.Both)
								}
								onChange={handleSelectHorizontalAlignment}
							>
								{options.horizontalAlignment.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Field>
						</StyledForm.Field>
						<ErrorMessage name="horizontalAlignment" />
					</StyledForm.FieldWrapper>
					{/** Vertical Alignment */}
					<StyledForm.FieldWrapper>
						<StyledForm.Label htmlFor="verticalAlignment">
							Vertical Alignment
						</StyledForm.Label>
						<StyledForm.Field>
							<Field
								name="verticalAlignment"
								as="select"
								disabled={
									isEqual<FormValues, Stretch>(
										props,
										"stretch",
										Stretch.Vertical
									) ||
									isEqual<FormValues, Stretch>(props, "stretch", Stretch.Both)
								}
								onChange={handleSelectVerticalAlignment}
							>
								{options.verticalAlignment.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</Field>
						</StyledForm.Field>
						<ErrorMessage name="verticalAlignment" />
					</StyledForm.FieldWrapper>
				</div>
			)}
		</Form>
	);
};

const AutoCommitInnerForm = withAutoCommit<InnerFormProps, FormValues>(
	InnerForm
);

const LinkPropertiesFormSchema = YupObject<FormValues>().shape({
	width: YupNumber().min(0),
	height: YupNumber().min(0),
	x: YupNumber().min(0),
	y: YupNumber().min(0),
});

export interface LinkPropertiesFormProps extends OtherProps {
	initialValues: FormValues;
	values?: FormValues;
	onSubmit?: (values: FormValues) => void;
}

export const LinkPropertiesForm: React.FC<LinkPropertiesFormProps> = (
	props: LinkPropertiesFormProps
) => {
	const {initialValues, values, onSubmit} = props;
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={LinkPropertiesFormSchema}
			onSubmit={onSubmit}
		>
			{formProps => {
				const {setValues} = formProps;

				useEffect(() => {
					if (values) {
						setValues(values, false);
					}
				}, [values]);

				return <AutoCommitInnerForm {...props} {...formProps} />;
			}}
		</Formik>
	);
};

export default LinkPropertiesForm;
