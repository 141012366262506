import React, {Component} from "react";
import {getParentElements} from "../util/helpers";

interface DraggableOutsideProps {
	outsideHandler: () => void;
	ignoredOutsideClickClassName?: string;
}

/**
 * Component that alerts if you click outside of it
 */
export default class DraggableOutsideComponent extends Component<
	DraggableOutsideProps
> {
	protected containerRef = React.createRef<HTMLDivElement>();

	constructor(props: DraggableOutsideProps) {
		super(props);

		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	/**
	 * Alert if clicked on outside of element
	 */
	handleClickOutside(event: Event) {
		const {ignoredOutsideClickClassName} = this.props;

		const noIgnoredElement = ignoredOutsideClickClassName
			? !getParentElements(event.target).find(
					el =>
						el &&
						el.classList &&
						el.classList.contains(ignoredOutsideClickClassName)
			  )
			: true;

		if (
			this.containerRef &&
			!this.containerRef.current.contains(event.target as Node) &&
			noIgnoredElement
		) {
			this.props.outsideHandler();
		}
	}

	render() {
		return <div ref={this.containerRef}>{this.props.children}</div>;
	}
}
