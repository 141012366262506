/*
Ideally we would use https://emotion.sh/docs/@emotion/babel-preset-css-prop to replace the JSX pragma requirement. The
issue is that ts-loader processes this file before it reaches babel. To get ts-loader to play nice we need to change the
tsconfig value "jsx" from "react" to "preserve" https://github.com/emotion-js/emotion/issues/1748. But this change
appears to blow up something in our stack. I believe this may be caused by react-docgen-typescript-loader, but I haven't
looked farther into it.
 */
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import {jsx} from "@emotion/core";
import {useTheme} from "emotion-theming";
import React, {Fragment, useCallback} from "react";
import {ItemInnerProps, ItemProps} from "./Navigation.types";
import styles from "./Navigation.styles";
export const Item: React.FunctionComponent<ItemProps> = () => <Fragment />;
const ItemComponent: React.FunctionComponent<ItemInnerProps> = ({
	children,
	id,
	type,
	float,
	size,
	mode,
	isNested,
	activeItems,
	icon,
	handleClick,
}) => {
	const theme = useTheme();
	const {ItemStyles} = styles(theme);
	const active = id ? activeItems.indexOf(id) !== -1 : false;
	const onClick = useCallback(e => handleClick(id, e), [id, handleClick]);

	return (
		<li
			css={[ItemStyles({type, float, size, active, mode, isNested})]}
			onClick={onClick}
		>
			{icon && icon}
			{children && <span>{children}</span>}
		</li>
	);
};

export default ItemComponent;
