import * as React from "react";
import {FunctionComponent, ChangeEvent} from "react";

interface CheckBoxProps {
	id?: string;
	checked: boolean;
	label: string;
	disabled: boolean;
	onChange(event: ChangeEvent<HTMLInputElement>): void;
}

interface CheckBoxPropsNoLabel {
	id?: string;
	checked: boolean;
	disabled: boolean;
	onChange(event: ChangeEvent<HTMLInputElement>): void;
}

const Checkbox: FunctionComponent<CheckBoxPropsNoLabel> = ({
	checked,
	id,
	onChange,
	disabled,
}) => (
	<input
		type="checkbox"
		checked={checked}
		onChange={onChange}
		id={id}
		disabled={disabled}
	/>
);

const CheckBoxPresenter: FunctionComponent<CheckBoxProps> = props => {
	const {checked, label, onChange, id, disabled} = props;
	return (
		<label htmlFor={id}>
			<Checkbox
				disabled={disabled}
				checked={checked}
				onChange={onChange}
				id={id}
			/>
			<span> {label} </span>
		</label>
	);
};
export {CheckBoxPresenter, CheckBoxPresenter as default};
