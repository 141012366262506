import {css} from "@emotion/core";
const getCheckboxPadding = (size, spacing) => {
	switch (size) {
		case "large":
			return spacing.md;
			break;
		case "small":
			return spacing.sm;
			break;
		case "middle":
		default:
			return spacing.md;
			break;
	}
};
const getFontSize = (size, fontSize) => {
	switch (size) {
		case "large":
			return fontSize.xl;
			break;
		case "small":
			return fontSize.sm;
			break;
		case "middle":
		default:
			return fontSize.md;
			break;
	}
};
const getFloat = type => {
	switch (type) {
		case "left":
			return "flex-start";
			break;
		case "right":
			return "flex-end";
			break;
		case "middle":
			return "center";
			break;
		case "between":
			return "space-between";
			break;
		default:
			return "space-between";
			break;
	}
};
const ToolboxModeStyles = ({theme, active}) =>
	css`
		margin: ${theme.spacing.xxs};
		border-width: 0 !important;
		background-color: ${active ? theme.colors.primary.lighter : "transparent"};
		&:hover {
			color: ${theme.colors.primary.dark};
			background-color: ${active
				? theme.colors.primary.lighter
				: "transparent"};
		}
	`;

const CheckboxModeStyles = ({theme, isNested, active}) =>
	css`
		&:after {
			position: absolute;
			right: ${theme.spacing.xxs};
			padding-left: ${theme.spacing.xs};
			content: "\\2713";
			display: inline-block;
			transform: scale(${active && isNested ? 1 : 0});
			transition: transform 0.3s;
			pointer-events: none;
		}
	`;

const styles = theme => {
	return {
		wrapperStyles: ({type, float}) => css`
			padding: 0;
			margin: 0;
			list-style-type: none;
			display: ${type === "vertical" ? "flex-inline" : "flex"};
			flex-direction: ${type === "vertical" ? "column" : "row"};
			color: ${theme.colors.primary.medium};
			display: ${type === "vertical" ? "inline-flex" : "flex"};
			font-family: ${theme.typography.family};
			justify-content: ${getFloat(float)};
		`,
		SubmenuStyles: ({type, float}) => {
			return [
				css`
					position: relative;
					padding: ${theme.spacing.xs};
					display: flex;
					justify-content: center;
					align-items: center;
					border-style: solid;
					&:hover {
						> ul {
							z-index: 10;
							width: ${float !== "between" ? "auto" : "none"};
							opacity: 1;
							visibility: visible;
							> li {
								color: white;
								border-color: transparent;
							}
						}
					}
					> ul {
						display: inline-flex;
						flex-direction: column;
						visibility: hidden;
						width: 100%;
						opacity: 0;
						padding: 0;
						position: absolute;
						background: ${theme.colors.primary.medium};
						top: ${type === "vertical" ? 0 : "100%"};
						left: ${type === "vertical" ? "100%" : 0};
						ul {
							top: 0;
							left: 100%;
						}
						> li {
							display: flex;
							justify-content: flex-start;
							margin: 0;
						}
					}
				`,
			];
		},
		ItemStyles: ({type, mode, float, size, active, isNested}) => {
			const {spacing, colors, typography} = theme;
			return [
				css`
					padding: ${spacing.xs};
					display: flex;
					flex: ${float === "between" ? 1 : "initial"};
					margin: 0 ${spacing.xs};
					position: relative;
					align-items: center;
					border-style: solid;
					border-width: 0;
					justify-content: ${type === "vertical" ? "flex-start" : "center"};
					font-size: ${getFontSize(size, typography.size)};
					border-color: ${active ? colors.primary.medium : "transparent"};
					border-right-width: ${type === "vertical" ? "2px" : 0};
					border-bottom-width: ${type === "horizontal" ? "2px" : 0};
					background-color: ${isNested && active
						? colors.primary.light
						: "none"};
					span {
						white-space: nowrap;
						padding-right: ${mode === "checkbox" && isNested
							? getCheckboxPadding(size, spacing)
							: 0};
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						a {
							color: inherit;
							text-decoration: none;
						}
					}
					span span {
						padding-right: 0;
					}
					span + span {
						margin-left: ${spacing.xs};
					}
					&:hover {
						cursor: pointer;
						color: ${colors.primary.dark};
						background-color: ${isNested ? colors.primary.light : "none"};
					}
				`,
				mode === "toolbox" && !isNested
					? ToolboxModeStyles({theme, active})
					: null,
				mode === "checkbox" && isNested
					? CheckboxModeStyles({theme, isNested, active})
					: null,
			];
		},
	};
};
export default styles;
