export enum TooltipType {
	/**
	 * Text media tooltips shows.
	 */
	Text = 0,

	/**
	 * Media tooltips show media (e.g. an image or a SWF) in the tooltip.
	 */
	Media = 1,
}

export enum Stretch {
	/**
	 * No stretch.
	 */
	None = "NONE",
	/**
	 * Stretch horizontally. Takes up all horizontal space available.
	 */
	Horizontal = "HORIZONTAL",
	/**
	 * Stretch vertically. Takes up all vertical space available.
	 */
	Vertical = "VERTICAL",
	/**
	 * Stretch horizontally and vertically. Takes up all space available.
	 */
	Both = "BOTH",
}

export enum HorizontalAlignment {
	/**
	 * No alignment.
	 */
	None = 0,

	/**
	 * Align to the left.
	 */
	Left = 1,

	/**
	 * Align to the center.
	 */
	Center = 2,

	/**
	 * Align to the right.
	 */
	Right = 3,
}

export enum VerticalAlignment {
	/**
	 * No alignment.
	 */
	None = 0,

	/**
	 * Align to the top.
	 */
	Top = 1,

	/**
	 * Align to the middle.
	 */
	Middle = 2,

	/**
	 * Align to the bottom.
	 */
	Bottom = 3,
}

export enum Effect {
	/**
	 * No effect.
	 */
	None = 0,

	/**
	 * Fade in the out.
	 */
	FADE_INOUT = 1,

	/**
	 * Continually fade in and out.
	 */
	PULSATING = 2,

	/**
	 * Frames the link with a shadow.
	 */
	SHADOW_FRAME = 3,

	/**
	 * Frames the link.
	 */
	FRAME = 4,
}

export interface BaseLink {
	publicationID: number;
	id: number;
	// rotation?: number;
	// color: number;
	// stateScope: number;
	width: number;
	height: number;
	alpha: number;
	x: number;
	y: number;
	fromPageNumber?: number;
	toPageNumber?: number;
	tooltip?: string;
	tooltipType: TooltipType;
	tooltipMediaSource?: number;
	tooltipMediaTitle?: string;
	tooltipMediaDescription?: string;
	effect?: Effect;
	externalSource?: number;
	stretch?: Stretch;
	horizontalAlignment?: HorizontalAlignment;
	verticalAlignment?: VerticalAlignment;
	// External Link
	url?: string;
	target?: string;
	lightBox?: boolean;
	lightBoxWidth?: number;
	lightBoxHeight?: number;
	// Product Link
	magid?: number;
	price?: number;
	description?: string;
	itemName?: string;
	productID?: string;
	useProductDatabase?: boolean;
	hasProduct?: boolean;
	// Internal Link
	associatedSKU?: string;
	pageNumber?: number;
}

export interface ProductLink {
	magid?: number;
	price?: number;
	description?: string;
	itemName?: string;
	productID?: string;
}
