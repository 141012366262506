import merge from "lodash.merge";
import defaultStyles from "./default";
import {Theme} from "./theme";
import {generateCommonColorSpectrum} from "./utility";
const customTheme: Theme = merge({}, defaultStyles, {
	colors: {
		primary: generateCommonColorSpectrum("#16b6cd"),
		feedback: {
			success: "#28a745",
			error: "#dc3545",
			warning: "#ffc107",
			default:"#6c757d",
			info: "#17a2b8"
		},
		text: "#ffffff",
	},
	spacing: {
		md: "30px",
	},
});

export default customTheme;
