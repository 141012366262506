/*
Ideally we would use https://emotion.sh/docs/@emotion/babel-preset-css-prop to replace the JSX pragma requirement. The
issue is that ts-loader processes this file before it reaches babel. To get ts-loader to play nice we need to change the
tsconfig value "jsx" from "react" to "preserve" https://github.com/emotion-js/emotion/issues/1748. But this change
appears to blow up something in our stack. I believe this may be caused by react-docgen-typescript-loader, but I haven't
looked farther into it.
 */
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import {jsx} from "@emotion/core";
import {useTheme} from "emotion-theming";
import React, {Fragment, Children, useCallback} from "react";
import {SubmenuInnerProps, SubmenuProps} from "./Navigation.types";
import {renderChild} from "./Navigation.utils";
import styles from "./Navigation.styles";
export const Submenu: React.FunctionComponent<SubmenuProps> = () => (
	<Fragment />
);
const SubmenuComponent: React.FunctionComponent<SubmenuInnerProps> = ({
	id,
	children,
	type,
	mode,
	float,
	size,
	icon,
	title,
	isNested,
	activeItems,
	handleClick,
}) => {
	const commonProps = {type, mode, size, float, activeItems, handleClick};

	const theme = useTheme();
	const {SubmenuStyles, ItemStyles} = styles(theme);
	const onClick = useCallback(e => handleClick(id, e), [id, handleClick]);

	const active = id ? activeItems.indexOf(id) !== -1 : false;
	const childrenWithProps = Children.map<
		{},
		React.FunctionComponentElement<SubmenuInnerProps>
	>(
		children,
		(
			child: React.FunctionComponentElement<SubmenuInnerProps>
		): React.FunctionComponentElement<SubmenuInnerProps> =>
			renderChild({
				childType: child.type,
				childProps: child.props,
				commonProps,
				isNested: true,
			})
	);
	return (
		<li
			css={[
				SubmenuStyles({type, float}),
				ItemStyles({active, type, float, size, mode, isNested}),
			]}
		>
			<span className={"sub-item-wrapper"} onClick={onClick}>
				{icon}
				{title && <span>{title}</span>}
			</span>
			<ul>{childrenWithProps}</ul>
		</li>
	);
};

export default SubmenuComponent;
