import * as React from "react";
import {
	useRef,
	useCallback,
	useEffect,
	useState,
	ForwardRefRenderFunction,
	useImperativeHandle,
	forwardRef,
} from "react";
import chunk from "lodash.chunk";
import Konva from "konva";
import {Stage, Layer, Image} from "react-konva";

import {
	DrawShapeHandles,
	ActionAreaProps,
	PolygonShape,
	ActionAreaHandles,
	RectPoints,
} from "./ContentActionArea.types";

import Shape from "./Shape";
import DrawPolygonArea from "./DrawPolygonArea";
import DrawRectangleArea from "./DrawRectangleArea";

const ActionAreaComponent: ForwardRefRenderFunction<
	ActionAreaHandles,
	ActionAreaProps
	> = (
	{
		drawMode,
		transformMode,
		drawingFillColor = "red",
		drawingStrokeColor = "blue",
		drawingStrokeWidth = 1,
		drawingOpacity = 0,
		fill = "orange",
		stroke = "orange",
		strokeWidth = 0.5,
		opacity = 0.8,
		idPrefix = "shape",
		onShapeAdded,
		onShapeRemoved,
		onShapeChanged,
		onShapeFocused,
		onShapeBlur,
		onClick,
		onDragSelect,
		shapes,
		image,
		scaleIndex = undefined,
		fitImageToStage = true,
		width,
		height,
		transformKeepRatio = false,
		transformRotateEnabled = true,
		transformCenteredScaling = false,
	},
	ref
) => {
	useImperativeHandle(ref, () => ({
		getShapes: () => shapes,
	}));

	const [isTransforming, setIsTransforming] = useState(false);
	const [scaling, setScaling] = useState(scaleIndex);
	const [selectedId, selectShape] = useState(null);
	const [isMouseDownPressed, setIsMouseDownPressed] = useState(false);
	const [isMovedEvent, setIsMovedEvent] = useState(false);
	const layer = useRef(null);
	const imageRef = useRef(null);
	const drawerRef = useRef<DrawShapeHandles>(null);
	const clickedOnShape = (e: Konva.KonvaEventObject<MouseEvent>) =>
		!e.target.attrs.hasOwnProperty("image") && e.target !== e.target.getStage();

	function findCollision(selectAreaPoints: RectPoints) {
		// In case some prop of array is undefined or doesn't exists
		// Math.min will return NaN
		const xStart = Math.min(selectAreaPoints[0], selectAreaPoints[4]);
		const yStart = Math.min(selectAreaPoints[1], selectAreaPoints[5]);
		const xEnd = Math.max(selectAreaPoints[0], selectAreaPoints[4]);
		const yEnd = Math.max(selectAreaPoints[1], selectAreaPoints[5]);

		// And here we have checks for NaN
		if (isNaN(xStart) || isNaN(yStart) || isNaN(xEnd) || isNaN(yEnd)) {
			return;
		}
		const selectedShapes: PolygonShape[] = [];
		shapes.forEach((shape) => {
			const chunked = chunk(shape.points, 2);
			for (let i = 0; i < chunked.length; i++) {
				const [x, y] = chunked[i];
				if (x >= xStart && x <= xEnd && y >= yStart && y <= yEnd) {
					selectedShapes.push(shape);
					break;
				}
			}
		});
		if (onDragSelect) {
			onDragSelect(selectedShapes);
		}
		setIsMovedEvent(false);
	}

	const addShape = useCallback(
		(points) => {
			const shapeToAdd = {
				id: `${idPrefix}${shapes.length + 1}`,
				points,
				fill,
				stroke,
				opacity,
				strokeWidth,
			};
			if (onShapeAdded) {
				onShapeAdded(shapeToAdd);
			}
			setIsMovedEvent(false);
		},
		[shapes, setIsMovedEvent]
	);

	const removeShape = useCallback(
		(id) => {
			const index = shapes.findIndex((s) => s.id === id);
			if (onShapeRemoved) {
				onShapeRemoved(shapes[index]);
			}
		},
		[shapes]
	);

	const handleShapeChanges = useCallback(
		(shape: PolygonShape) => {
			const shapesToModify = [...shapes];
			const index = shapesToModify.findIndex((s) => s.id === shape.id);
			shapesToModify[index] = shape;
			if (onShapeChanged) {
				onShapeChanged(shape);
			}
		},
		[shapes]
	);

	const keyDownHandler = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>) => {
			if (e.key === "Escape") {
				drawerRef.current?.clear();
			}
			if (
				removeShape &&
				selectedId &&
				(e.key === "Backspace" || e.key === "Delete")
			) {
				removeShape(selectedId);
			}
		},
		[selectedId, drawerRef]
	);

	const mouseMoveHandler = useCallback(
		(e: Konva.KonvaEventObject<MouseEvent>) => {
			if (isMouseDownPressed || (drawMode === "polygon" && !transformMode)) {
				drawerRef.current?.handleMouseMove(e);
				setIsMovedEvent(true);
			} else {
				setIsMovedEvent(false);
			}
		},
		[isMouseDownPressed, setIsMovedEvent, drawMode, isTransforming, drawerRef]
	);
	const mouseDownHandler = useCallback(
		(e: Konva.KonvaEventObject<MouseEvent>) => {
			if (!clickedOnShape(e) || !transformMode) {
				drawerRef.current?.handleMouseDown(e);
				onShapeBlur ? onShapeBlur(selectedId) : null;
				selectShape(null);
			}
			setIsMouseDownPressed(true);
		},
		[
			selectedId,
			drawerRef,
			transformMode,
			onShapeBlur,
			setIsMouseDownPressed,
		]
	);

	const mouseUpHandler = useCallback(
		(e: Konva.KonvaEventObject<MouseEvent> | MouseEvent) => {
			setIsMouseDownPressed(false);
			drawerRef.current?.handleMouseUp(e);
		},
		[drawerRef, setIsMouseDownPressed]
	);

	const mouseClickHandler = useCallback(
		(e: Konva.KonvaEventObject<MouseEvent>) => {
			if (onClick && !isMovedEvent) {
				onClick({
					isClickedOnShape: clickedOnShape(e),
					isTransformMode: transformMode !== undefined,
					event: e.evt,
				});
			}
		},
		[transformMode, isMovedEvent, onClick]
	);

	const handleSelectShape = useCallback(
		({id, type, event}) => {
			selectShape(id);
			if (onShapeFocused) {
				onShapeFocused({id, type, event});
			}
		},
		[selectShape, onShapeFocused]
	);

	useEffect(() => {
		window.addEventListener("mouseup", mouseUpHandler);
		return () => {
			window.removeEventListener("mouseup", mouseUpHandler);
		};
	}, [mouseUpHandler]);

	useEffect(() => {
		if (image && fitImageToStage && !scaling) {
			const {naturalHeight, naturalWidth} = image;
			let scalingY = 1;
			let scalingX = 1;
			if (naturalHeight > height) {
				scalingY = height / naturalHeight;
			}
			if (naturalWidth > width) {
				scalingX = width / naturalWidth;
			}
			setScaling(Math.min(scalingY, scalingX));
		} else {
			setScaling(scaleIndex);
		}
	}, [image, fitImageToStage, scaleIndex]);

	useEffect(() => {
		if (!transformMode) {
			selectShape(null);
		}
	}, [transformMode]);

	const dragOnly = transformMode === "dragOnly";

	function renderDrawer() {
		let drawArea = undefined;
		if (transformMode && !isTransforming) {
			drawArea = (
				<DrawRectangleArea
					ref={drawerRef}
					onFinish={findCollision}
					drawingFillColor="grey"
					drawingStrokeColor="black"
					drawingStrokeWidth={0.5}
					drawingOpacity={0.2}
					dash={[5, 5]}
				/>
			);
		} else if (!transformMode && drawMode === "rectangle") {
			drawArea = (
				<DrawRectangleArea
					ref={drawerRef}
					onFinish={addShape}
					drawingFillColor={drawingFillColor}
					drawingStrokeColor={drawingStrokeColor}
					drawingStrokeWidth={drawingStrokeWidth}
					drawingOpacity={drawingOpacity}
				/>
			);
		} else if (!transformMode && drawMode === "polygon") {
			drawArea = (
				<DrawPolygonArea
					ref={drawerRef}
					onFinish={addShape}
					scaleIndex={scaleIndex}
					drawingFillColor={drawingFillColor}
					drawingStrokeColor={drawingStrokeColor}
					drawingStrokeWidth={drawingStrokeWidth}
					drawingOpacity={drawingOpacity}
				/>
			);
		}
		return drawArea;
	}

	return (
		<div
			id="container"
			tabIndex={1}
			onKeyDown={keyDownHandler}
			style={{outlineStyle: "none"}}
		>
			<Stage
				onMouseMove={mouseMoveHandler}
				onMouseDown={mouseDownHandler}
				onClick={mouseClickHandler}
				onMouseUp={mouseUpHandler}
				scaleX={scaling}
				scaleY={scaling}
				width={width}
				height={height}
			>
				<Layer ref={layer}>
					{image && (
						<Image
							ref={imageRef}
							preventDefault={false}
							scaleX={1}
							scaleY={1}
							image={image}
						/>
					)}
					{shapes.map((shape) => {
						const {
							id,
							points,
							fill: shapeFill,
							stroke: shapeStroke,
							strokeWidth: shapeStrokeWidth,
							opacity: shapeOpacity,
							groupElements,
							isShapeSelected,
							type,
						} = shape;
						return (
							<Shape
								id={id}
								key={id}
								points={points}
								isSelected={
									transformMode && (isShapeSelected ?? id === selectedId)
								}
								draggable={
									(transformMode && (isShapeSelected ?? id === selectedId)) ||
									dragOnly
								}
								onSelect={transformMode ? handleSelectShape : undefined}
								handleIsTransforming={setIsTransforming}
								onChange={handleShapeChanges}
								dragOnly={dragOnly}
								keepRatio={transformKeepRatio}
								rotateEnabled={transformRotateEnabled}
								centeredScaling={transformCenteredScaling}
								fill={shapeFill || fill}
								stroke={shapeStroke || stroke}
								strokeWidth={shapeStrokeWidth || strokeWidth}
								opacity={shapeOpacity || opacity}
								groupElements={groupElements}
								type={type}
							/>
						);
					})}
					{renderDrawer()}
				</Layer>
			</Stage>
		</div>
	);
};

export default forwardRef(ActionAreaComponent);
