import * as React from "react";
import {Children} from "react";
import {useCallback, useEffect} from "react";
import Konva from "konva";
import {Line, Group, Transformer} from "react-konva";
import chunk from "lodash.chunk";
import {PolygonShapeComponentProps} from "./ContentActionArea.types";
const Shape: React.FunctionComponent<PolygonShapeComponentProps> = ({
	points: inputPoints,
	id,
	fill,
	stroke,
	strokeWidth,
	opacity,
	draggable,
	isSelected,
	dragOnly,
	keepRatio,
	rotateEnabled,
	centeredScaling,
	onSelect,
	onChange,
	groupElements,
	type,
	handleIsTransforming,
}) => {
	const shapeRef = React.useRef<Konva.Line>(undefined);
	const [childrenElementsDelta, updateChildrenElementsDelta] = React.useState<
		Array<Array<number>>
		>([]);
	const [minPoints, setMinPoints] = React.useState<Array<number>>([0, 0]);
	const resetTransformationParams = () => {
		shapeRef.current.x(0);
		shapeRef.current.y(0);
		shapeRef.current.rotation(0);
		shapeRef.current.scaleX(1);
		shapeRef.current.scaleY(1);
	};
	const getBoundingRectTopLeftVertex = (points) => {
		const chunked = chunk(points, 2);
		let topLeftX = chunked[0][0];
		let topLeftY = chunked[0][1];
		chunked.forEach(([x, y]) => {
			topLeftX = Math.min(topLeftX, x);
			topLeftY = Math.min(topLeftY, y);
		});
		return [topLeftX, topLeftY];
	};
	const trRef = React.useRef<Konva.Transformer>(undefined);
	const handleSelect = useCallback(
		({evt}) => {
			onSelect ? onSelect({id, type, event: evt}) : null;
		},
		[id, type, onSelect]
	);

	const handleTransformationStart = useCallback(() => {
		handleIsTransforming(true);
	}, [handleIsTransforming]);

	const handleTransformationEnd = useCallback(
		(event: Konva.KonvaEventObject<MouseEvent>) => {
			const transformedPoints = [];
			const {points} = event.currentTarget.attrs;
			const chunked = chunk(points, 2);
			const stage = event.target.getStage();
			const transform = stage.getTransform().copy();
			transform.invert();
			chunked.forEach(([x, y]) => {
				const currentPoints = shapeRef.current.getTransform().point({x, y});
				const {x: absoluteX, y: absoluteY} = currentPoints;
				transformedPoints.push(...[absoluteX, absoluteY]);
			});

			if (transformedPoints) {
				resetTransformationParams();
				const [minX, minY] = minPoints;
				const modifiedGroupElements = groupElements
					? groupElements.map((groupElement, index) => {
						const [deltaX, deltaY] = childrenElementsDelta[index];
						return {
							...groupElement,
							props: {
								...groupElement.props,
								x: minX - deltaX,
								y: minY - deltaY,
							},
						};
					})
					: undefined;
				onChange({
					id,
					points: transformedPoints,
					fill,
					stroke,
					strokeWidth,
					opacity,
					groupElements: modifiedGroupElements,
					type,
				});
			}
			handleIsTransforming(false);
		},
		[childrenElementsDelta, groupElements, minPoints, handleIsTransforming]
	);

	function handleMoveChanges(event: Konva.KonvaEventObject<MouseEvent>) {
		const stage = event.target.getStage();
		const {x, y} = event.currentTarget.getClientRect({
			skipTransform: false,
			skipStroke: true,
			relativeTo: stage,
		});
		setMinPoints([x, y]);
	}

	function handleChildTransformation(
		event: Konva.KonvaEventObject<MouseEvent>
	) {
		if (!groupElements) {
			return;
		}
		const modifiedGroupElements = groupElements.map((groupElement) => {
			if (groupElement.key === event.currentTarget.index.toString()) {
				return {
					...groupElement,
					props: {
						...groupElement.props,
						x: event.currentTarget.attrs.x,
						y: event.currentTarget.attrs.y,
					},
				};
			}
			return groupElement;
		});
		onChange({
			id,
			points: inputPoints,
			fill,
			stroke,
			strokeWidth,
			opacity,
			groupElements: modifiedGroupElements,
			type,
		});
	}

	useEffect(() => {
		if (isSelected) {
			trRef.current?.setNode(shapeRef.current);
			trRef.current?.getLayer().batchDraw();
		}
	}, [isSelected]);

	useEffect(() => {
		if (groupElements) {
			const [minParentX, minParentY] = getBoundingRectTopLeftVertex(inputPoints);
			const deltaArray = [];
			groupElements.forEach((groupElement, index) => {
				const {x, y} = groupElement.props;
				deltaArray[index] = [minParentX - x, minParentY - y];
			});
			setMinPoints([minParentX, minParentY]);
			updateChildrenElementsDelta(deltaArray);
		}
	}, [groupElements, updateChildrenElementsDelta]);

	const [minX, minY] = minPoints;
	return (
		<>
			<Group>
				<Line
					draggable={draggable}
					id={id}
					points={inputPoints}
					fill={fill}
					stroke={stroke}
					strokeWidth={strokeWidth}
					opacity={opacity}
					closed={true}
					onClick={handleSelect}
					onTap={handleSelect}
					onDragMove={handleMoveChanges}
					onDragEnd={handleTransformationEnd}
					onDragStart={handleTransformationStart}
					onTransformEnd={handleTransformationEnd}
					onTransformStart={handleTransformationStart}
					onTransform={handleMoveChanges}
					ref={shapeRef}
				/>
				{Children.map(groupElements, (element, index) => {
					const {props} = element;
					const [deltaX = 0, deltaY = 0] = childrenElementsDelta[index] || [];
					return React.cloneElement(
						element,
						{
							...props,
							parentId: id,
							x: minX - deltaX,
							y: minY - deltaY,
							id: index,
							draggable: true,
							onClick: handleSelect,
							onTap: handleSelect,
							onDragStart: handleSelect,
							onDragEnd: handleChildTransformation,
						},
						props.children
					);
				})}
			</Group>
			{isSelected && !dragOnly && (
				<Transformer
					keepRatio={keepRatio}
					rotateEnabled={rotateEnabled}
					centeredScaling={centeredScaling}
					ref={trRef}
				/>
			)}
		</>
	);
};
export default Shape;
