import styled from "@emotion/styled";

export const UploadContainer = styled.div`
	display: inline-flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 4px;
	position: relative;
`;

export const DropZone = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
`;

export const FilesList = styled.div`
	padding: 6px;
`;

export const File = styled.div`
	display: flex;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	align-items: center;
	justify-content: flex-start;
	font-size: 14px;
	> * {
		padding-right: 4px;
	}
	> span {
		color: rgba(0, 0, 0, 0.45);
	}
`;
