export interface FormState<Values> {
	/** Form values */
	values: Values;
}

export const isEqual = <V, T extends unknown>(
	props: FormState<V>,
	name: keyof V,
	expected: T
): boolean => (props.values[name] as T) === expected;
