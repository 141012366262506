/** @jsx jsx */
import {jsx} from "@emotion/core";
import React from "react";

import {Theme} from "../themes/theme";
import {useTheme} from "emotion-theming";
import styles from "./DividerComponent.styles";

export enum DividerDirection {
	HORIZONTAL = "horizontal",
	VERTICAL = "vertical",
}

export interface DividerProps {
	/** The direction type of divider */
	direction: DividerDirection;
}

const DividerComponent: React.FunctionComponent<DividerProps> = (
	props: DividerProps
): JSX.Element => {
	const {direction} = props;
	const theme: Theme = useTheme();
	const style = styles(theme);
	return (
		<div
			css={[
				direction === DividerDirection.VERTICAL
					? style.vertical()
					: style.horizontal(),
			]}
		/>
	);
};

export {DividerComponent as default, DividerComponent};
