import React, {useCallback, useEffect} from "react";
import debounce from "lodash.debounce";

import {InjectedFormikProps} from "formik";

export type FormikForm<Props, Values> = React.ComponentType<
	InjectedFormikProps<Props, Values>
>;

export const withAutoCommit = <Props, Values>(
	Component: FormikForm<Props, Values>
): FormikForm<Props, Values> => (props: InjectedFormikProps<Props, Values>) => {
	const {values, dirty, submitForm} = props;

	const debouncedSubmitForm = useCallback(debounce(submitForm, 500), [
		submitForm,
	]);

	useEffect(() => {
		if (dirty) {
			debouncedSubmitForm();
		}
		return debouncedSubmitForm.cancel;
	}, [values]);

	return React.createElement(Component, props);
};

export default withAutoCommit;
