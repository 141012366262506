import styled from "@emotion/styled";

export const FieldWrapper = styled.div`
	padding: 4px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	flex: 1 1 auto;
`;

export const Field = styled.div`
	flex: 1 1 auto;
	&&& > *,
	&&& input[type="text"] {
		width: 100%;
		box-sizing: border-box;
	}
	&&& > input[type="checkbox"] {
		width: auto;
		padding: 0;
		margin: 0;
		vertical-align: middle;
	}
`;

export const Group = styled.div`
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	&&& > * {
		flex: 1 1 auto;
	}
`;

export const LabelH1 = styled.label`
	text-align: right;
	margin-right: 6px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 18px;
	font-weight: bold;
`;

export const Label = styled.label`
	flex: 0 0 124px;
	text-align: right;
	text-align: right;
	margin-right: 6px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 14px;
`;
